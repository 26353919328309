
import { ComprVerbalQuestion, ComprAnswer, ComprVerbalCategoryInfo, ComprMathCategoryInfo, } from '@app/Models/Comprehensive';

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { ComprStudentAuthorization, ComprStudentAnswer, ComprStudentResult, ScoringComprData } from '@app/Models/Comprehensive';
import { SurveyAnswer, SurveyData } from '@app/Models/Survey';

export class CategoriesInfo {
  getVerbalCategoryInfo(categoryId) {
    if (categoryId == 1) return this.clone(this.vocabulary);
    else if (categoryId == 2) return this.clone(this.spelling);
    else if (categoryId == 3) return this.clone(this.languageMechanics);
    else if (categoryId == 4) return this.clone(this.readingComprehension);
  }
  getMathCategoryInfo(categoryId) {
    if (categoryId == 5) return this.clone(this.mathComputation);
    else if (categoryId == 6) return this.clone(this.mathApplication);
  }

  clone(obj) {
    if (obj == null || typeof (obj) != 'object') {
      return obj;
    }
    var temp = new obj.constructor();
    for (var key in obj) {
      temp[key] = this.clone(obj[key]);
    }
    return temp;
  }

  vocabulary: ComprVerbalCategoryInfo = {
    Category: 'Vocabulary',
    Directions: 'This section has questions about words and what they mean. Samples of these questions are shown below. Read each question carefully and then mark your answer.',
    Time: 600,
    Samples:
      [
        {
          IsPassage: false,
          GroupDescription: "Read the sentence and choose the <b>best meaning</b> for the <u>underlined</u> word.",
          GroupPassage: "",
          Questions: [new ComprVerbalQuestion("1", "The bus was <u>speeding</u> down the road.", "B", [
            new ComprAnswer("A", "going the wrong way"), new ComprAnswer("B", "going very fast"), new ComprAnswer("C", "out of control"), new ComprAnswer("D", "going very slowly")
          ])
          ]
        },
        {
          IsPassage: false,
          GroupDescription: "Choose the word that <b>means the same</b> as the <u>underlined</u> word.",
          GroupPassage: "",
          Questions: [new ComprVerbalQuestion("2", "<u>happy</u> is the <b>same</b> as<span class='mx-2 px-4 border-bottom border-dark'></span>", "A", [
            new ComprAnswer("A", "glad"), new ComprAnswer("B", "sad"), new ComprAnswer("C", "funny"), new ComprAnswer("D", "mad")
          ])
          ]
        },
        {
          IsPassage: false,
          GroupDescription: "Choose the word that <b>means the opposite</b> of the <u>underlined</u> word.",
          GroupPassage: "",
          Questions: [new ComprVerbalQuestion("3", "<u>hot</u> is the <b>opposite</b> of<span class='mx-2 px-4 border-bottom border-dark'></span>", "C", [
            new ComprAnswer("A", "warm"), new ComprAnswer("B", "humid"), new ComprAnswer("C", "cold"), new ComprAnswer("D", "cozy")
          ])
          ]
        },
        {
          IsPassage: false,
          GroupDescription: "Choose the word that <b>best completes</b> the sentence.",
          GroupPassage: "",
          Questions: [new ComprVerbalQuestion("4", "Ericka was a good runner, but Paula was a<span class='mx-2 px-4 border-bottom border-dark'></span>runner.", "D", [
            new ComprAnswer("A", "more"), new ComprAnswer("B", "longer"), new ComprAnswer("C", "best"), new ComprAnswer("D", "better")
          ])
          ]
        },
        {
          IsPassage: false,
          GroupDescription: "Choose the <b>best meaning</b> for the <u>underlined</u> word.",
          GroupPassage: "",
          Questions: [new ComprVerbalQuestion("5", "<u>find</u>", "A", [
            new ComprAnswer("A", "discover"), new ComprAnswer("B", "lose"), new ComprAnswer("C", "make"), new ComprAnswer("D", "change")
          ])
          ]
        },
        {
          IsPassage: false,
          GroupDescription: "Choose the pair of words that has the <b>same relationship</b> as the first pair of words.",
          GroupPassage: "",
          Questions: [new ComprVerbalQuestion("4", "<u>big</u> is to <u>small</u> <b>JUST AS</b><span class='mx-2 px-4 border-bottom border-dark'></span>", "D", [
            new ComprAnswer("A", "warm is to heat"), new ComprAnswer("B", "cold is to snow"), new ComprAnswer("C", "black is to grey"), new ComprAnswer("D", "tall is to short")
          ])
          ]
        },
        {
          IsPassage: false,
          GroupDescription: "Choose the <b>best meaning</b> for the <u>underlined</u> word.",
          GroupPassage: "",
          Questions: [new ComprVerbalQuestion("1", "<u>hemisphere</u>", "B", [
            new ComprAnswer("A", "a small globe"), new ComprAnswer("B", "half of a globe"), new ComprAnswer("C", "a whole globe"), new ComprAnswer("D", "a spinning globe")
          ])
          ]
        },
        {
          IsPassage: false,
          GroupDescription: "Read the sentence and choose the <b>best meaning</b> for the <u>underlined</u> words.",
          GroupPassage: "",
          Questions: [new ComprVerbalQuestion("2", "The homecoming committee was happy to <u>roll out the red carpet</u> for returning students.", "A", [
            new ComprAnswer("A", "offer a grand welcome"), new ComprAnswer("B", "decorate the school"), new ComprAnswer("C", "make a special speech"), new ComprAnswer("D", "raise prize money")
          ])
          ]
        },
        {
          IsPassage: false,
          GroupDescription: "Choose the word that <b>means the same</b> as the <u>underlined</u> word.",
          GroupPassage: "",
          Questions: [new ComprVerbalQuestion("3", "<u>port</u> is the <b>same</b> as<span class='mx-2 px-4 border-bottom border-dark'></span>", "D", [
            new ComprAnswer("A", "ship"), new ComprAnswer("B", "water"), new ComprAnswer("C", "beach"), new ComprAnswer("D", "harbor")
          ])
          ]
        },
        {
          IsPassage: false,
          GroupDescription: "Choose the word that <b>means the opposite</b> of the <u>underlined</u> word.",
          GroupPassage: "",
          Questions: [new ComprVerbalQuestion("4", "<u>prevent</u> is the <b>opposite</b> of<span class='mx-2 px-4 border-bottom border-dark'></span>", "D", [
            new ComprAnswer("A", "grow"), new ComprAnswer("B", "stall"), new ComprAnswer("C", "enjoy"), new ComprAnswer("D", "cause")
          ])
          ]
        },
        {
          IsPassage: false,
          GroupDescription: "Choose the pair of words that has the <b>same relationship</b> as the first pair of words.",
          GroupPassage: "",
          Questions: [new ComprVerbalQuestion("5", "<u>peculiar</u> is to <u>strange</u> <b>JUST AS</b><span class='mx-2 px-4 border-bottom border-dark'></span>", "A", [
            new ComprAnswer("A", "prolong is to extend"), new ComprAnswer("B", "justify is to annoy"), new ComprAnswer("C", "like is to dislike"), new ComprAnswer("D", "possible is to correct")
          ])
          ]
        },
        {
          IsPassage: false,
          GroupDescription: "Read the sentence and choose the <b>best meaning</b> for the <u>underlined</u> words.",
          GroupPassage: "",
          Questions: [new ComprVerbalQuestion("1", "After reading the reviews, Paul was still <u>on the fence</u> about which movie to see first.", "B", [
            new ComprAnswer("A", "critical"), new ComprAnswer("B", "undecided"), new ComprAnswer("C", "careless"), new ComprAnswer("D", "excited")
          ])
          ]
        },
        {
          IsPassage: false,
          GroupDescription: "Choose the <b>best meaning</b> for the <u>underlined</u> word.",
          GroupPassage: "",
          Questions: [new ComprVerbalQuestion("2", "<u>originate</u>", "A", [
            new ComprAnswer("A", "start from"), new ComprAnswer("B", "be limited to"), new ComprAnswer("C", "be completed"), new ComprAnswer("D", "break away from")
          ])
          ]
        },
        {
          IsPassage: false,
          GroupDescription: "Choose the word that <b>means the same</b> as the <u>underlined</u> word.",
          GroupPassage: "",
          Questions: [new ComprVerbalQuestion("3", "<u>mute</u> is the <b>same</b> as<span class='mx-2 px-4 border-bottom border-dark'></span>", "C", [
            new ComprAnswer("A", "loud"), new ComprAnswer("B", "gentle"), new ComprAnswer("C", "silent"), new ComprAnswer("D", "relaxed")
          ])
          ]
        },
        {
          IsPassage: false,
          GroupDescription: "Choose the word that <b>means the opposite</b> of the <u>underlined</u> word.",
          GroupPassage: "",
          Questions: [new ComprVerbalQuestion("4", "<u>massive</u> is the <b>opposite</b> of<span class='mx-2 px-4 border-bottom border-dark'></span>", "D", [
            new ComprAnswer("A", "heavy"), new ComprAnswer("B", "broken"), new ComprAnswer("C", "weak"), new ComprAnswer("D", "tiny")
          ])
          ]
        },
        {
          IsPassage: false,
          GroupDescription: "Choose the pair of words that has the <b>same relationship</b> as the first pair of words.",
          GroupPassage: "",
          Questions: [new ComprVerbalQuestion("5", "<u>rare</u> is to <u>frequent</u> <b>JUST AS</b><span class='mx-2 px-4 border-bottom border-dark'></span>", "A", [
            new ComprAnswer("A", "mean is to kind"), new ComprAnswer("B", "cruel is to harsh"), new ComprAnswer("C", "sad is to depressed"), new ComprAnswer("D", "caring is to gentle")
          ])
          ]
        }
      ]
  }
  spelling: ComprVerbalCategoryInfo = {
    Category: 'Spelling',
    Directions: 'This section has four kinds of spelling questions. Samples of these questions are shown below. Read each question carefully and then mark your answer.',
    Time: 600,
    Samples:
      [
        {
          IsPassage: false,
          GroupDescription: "Choose the <b>correctly spelled</b> word.",
          GroupPassage: "",
          Questions: [new ComprVerbalQuestion("1", "<b>For this type of question, a word is spelled four different ways. Only one is correct.</b>", "B", [
            new ComprAnswer("A", "citi"), new ComprAnswer("B", "city"), new ComprAnswer("C", "citie"), new ComprAnswer("D", "citty")
          ])
          ]
        },
        {
          IsPassage: false,
          GroupDescription: "Choose the <b>correctly spelled</b> word.",
          GroupPassage: "",
          Questions: [new ComprVerbalQuestion("2", "<b>For this type of question, there are four different words. Only one is spelled correctly.</b>", "A", [
            new ComprAnswer("A", "night"), new ComprAnswer("B", "weth"), new ComprAnswer("C", "thiz"), new ComprAnswer("D", "twoday")
          ])
          ]
        },
        {
          IsPassage: false,
          GroupDescription: "Choose the <b>misspelled</b> word.",
          GroupPassage: "",
          Questions: [new ComprVerbalQuestion("3", "<b>For this type of question, four different words are listed. Only one is misspelled.</b>", "C", [
            new ComprAnswer("A", "cat"), new ComprAnswer("B", "dog"), new ComprAnswer("C", "aple"), new ComprAnswer("D", "jump")
          ])
          ]
        },
        {
          IsPassage: false,
          GroupDescription: "Choose the <b>misspelled</b> word. If there is no misspelled word, choose option D, “no error”.",
          GroupPassage: "",
          Questions: [new ComprVerbalQuestion("4", "<b>For this type of question, three different words are listed. One might be misspelled or all three might be correct.</b>", "D", [
            new ComprAnswer("A", "now"), new ComprAnswer("B", "yes"), new ComprAnswer("C", "don’t"), new ComprAnswer("D", "no error")
          ])
          ]
        },
        {
          IsPassage: false,
          GroupDescription: "Choose the <b>correctly spelled</b> word.",
          GroupPassage: "",
          Questions: [new ComprVerbalQuestion("1", "<b>For this type of question, a word is spelled four different ways. Only one is correct.</b>", "A", [
            new ComprAnswer("A", "possession"), new ComprAnswer("B", "possesion"), new ComprAnswer("C", "possetion"), new ComprAnswer("D", "posession")
          ])
          ]
        },
        {
          IsPassage: false,
          GroupDescription: "Choose the <b>correctly spelled</b> word.",
          GroupPassage: "",
          Questions: [new ComprVerbalQuestion("2", "<b>For this type of question, there are four different words. Only one is spelled correctly.</b>", "C", [
            new ComprAnswer("A", "natoural"), new ComprAnswer("B", "regionall"), new ComprAnswer("C", "practical"), new ComprAnswer("D", "nationall")
          ])
          ]
        },
        {
          IsPassage: false,
          GroupDescription: "Choose the <b>misspelled</b> word.",
          GroupPassage: "",
          Questions: [new ComprVerbalQuestion("3", "<b>For this type of question, four different words are listed. Only one is misspelled.</b>", "B", [
            new ComprAnswer("A", "disband"), new ComprAnswer("B", "disconect"), new ComprAnswer("C", "disbelief"), new ComprAnswer("D", "discomfort")
          ])
          ]
        },
        {
          IsPassage: false,
          GroupDescription: "Choose the <b>misspelled</b> word. If there is no misspelled word, choose option D, “no error”.",
          GroupPassage: "",
          Questions: [new ComprVerbalQuestion("4", "<b>For this type of question, three different words are listed. One might be misspelled or all three might be correct.</b>", "D", [
            new ComprAnswer("A", "corps"), new ComprAnswer("B", "dozen"), new ComprAnswer("C", "angle"), new ComprAnswer("D", "no error")
          ])
          ]
        },
      ]


  }
  languageMechanics: ComprVerbalCategoryInfo = {
    Category: 'Language Mechanics',
    Directions: 'This section has questions that ask you to use your knowledge about grammar. There are questions about word use, punctuation, and capitalization. Read each question carefully and then mark your answer.',
    Time: 600,
    Samples: []
  }
  readingComprehension: ComprVerbalCategoryInfo = {
    Category: 'Reading Comprehension',
    Directions: 'This section has reading passages followed by several questions. Read the passage carefully and then answer the questions about that passage.',
    Time: 1800,
    Samples: []
  }
  mathComputation: ComprMathCategoryInfo = {
    Category: 'Math Computation',
    Directions: 'This section is made up of math problems. Read each problem carefully and then mark your answer. A sample math problem is shown below.',
    Time: 1200,
    Samples: [{
      QuestionId: "1",
      QuestionImage: null,
      QuestionText: "$${ \\;\\; 12 \\\\ + 6 \\over }$$",
      CorrectAnswer: "C",
      Submitted: false,
      SelectedAnswer: null,
      Answers: [new SurveyAnswer("A", "6"), new SurveyAnswer("B", "15"), new SurveyAnswer("C", "18"), new SurveyAnswer("D", "20")]
    }, {
      QuestionId: "1",
      QuestionImage: null,
      QuestionText: "If 5(x + 3) = 25, then x =",
      CorrectAnswer: "C",
      Submitted: false,
      SelectedAnswer: null,
      Answers: [new SurveyAnswer("A", "10"), new SurveyAnswer("B", "5"), new SurveyAnswer("C", "2"), new SurveyAnswer("D", "1")]
    }, {
      QuestionId: "1",
      QuestionImage: null,
      QuestionText: "Simplify.<br><br>(–4 x 3) + (–3 x 4) =",
      CorrectAnswer: "A",
      Submitted: false,
      SelectedAnswer: null,
      Answers: [new SurveyAnswer("A", "–24"), new SurveyAnswer("B", "0"), new SurveyAnswer("C", "24"), new SurveyAnswer("D", "48")]
    }]
  }
  mathApplication: ComprMathCategoryInfo = {
    Category: 'Math Application',
    Directions: 'This section requires you to use your knowledge of math to solve problems. Read each problem carefully and then mark your answer sheet. A sample problem is shown below.',
    Time: 2100,
    Samples: [{
      QuestionId: "1",
      QuestionImage: "",
      QuestionText: "Tony needs to go from his house to the airport. How many miles does he need to drive? <br> <img width='530'>",
      CorrectAnswer: "D",
      Submitted: false,
      SelectedAnswer: null,
      Answers: [new SurveyAnswer("A", "18"), new SurveyAnswer("B", "20"), new SurveyAnswer("C", "22"), new SurveyAnswer("D", "30")]
    }, {
      QuestionId: "1",
      QuestionImage: "",
      QuestionText: "Tony works at the local airport. He drives to and from work five days a week. If his car gets 20 miles to the gallon, how many gallons of gas does he use in one week of commuting? <br> <img width='530'>",
      CorrectAnswer: "D",
      Submitted: false,
      SelectedAnswer: null,
      Answers: [new SurveyAnswer("A", "1.5"), new SurveyAnswer("B", "3"), new SurveyAnswer("C", "7.5"), new SurveyAnswer("D", "15")]
    }, {
      QuestionId: "1",
      QuestionImage: null,
      QuestionText: "Pat works two days a week as a commissioned salesperson at XYZ Company. She cannot work on Fridays. According to the graph below, which two days should Pat work if she wants to make the most money? <br> <img width='530'>",
      CorrectAnswer: "B",
      Submitted: false,
      SelectedAnswer: null,
      Answers: [new SurveyAnswer("A", "Mondays and Wednesdays"), new SurveyAnswer("B", "Tuesdays and Wednesdays"), new SurveyAnswer("C", "Tuesdays and Thursdays"), new SurveyAnswer("D", "Wednesdays and Thursdays")]
    }]
  }
}



@Injectable()
export class ComprehensiveService extends CategoriesInfo {

  constructor(private http: HttpClient, @Inject('BASE_URL') public baseUrl: string) {
    super();
  }

  getVerbalData(categoryId: number, assessmentId: number, token: string): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseUrl}api/Comprehensive/GetVerbalQuestions/${categoryId}/${assessmentId}/${token}`).pipe(
      map(result => result),
      shareReplay()
    );
  }

  getMathData(categoryId: number, assessmentId: number, token: string): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseUrl}api/Comprehensive/GetMathQuestions/${categoryId}/${assessmentId}/${token}`).pipe(
      map(result => result),
      shareReplay()
    );
  }

  submitComprAnswers(auth: ComprStudentAuthorization, answers: ComprStudentAnswer[]): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}api/Comprehensive/SubmitAnswers`, { auth, answers }).pipe(
      shareReplay()
    );
  }

  getStudentResults(studentId: number): Observable<ComprStudentResult[]> {
    return this.http.get<ComprStudentResult[]>(`${this.baseUrl}api/Comprehensive/GetStudentComprehensiveResults/${studentId}`).pipe(
      map(result => result),
      shareReplay()
    );
  }

  getComprehensiveScoringData(form: string, level: string): Observable<ScoringComprData> {
    return this.http.get<ScoringComprData>(`${this.baseUrl}api/Comprehensive/GetComprehensiveScoringData/${form}/${level}`).pipe(
      map(result => result),
      shareReplay()
    );
  }

  scoreTest(assessmentId: number): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}api/Comprehensive/ScoreTest/${assessmentId}`).pipe(
      map(result => result),
      shareReplay()
    );
  }

  getCategoryIsAllowed(data: SurveyData, categoryId: number): boolean {
    switch (categoryId) {
      case 1: return data.TakeVocabulary;
      case 2: return data.TakeSpelling;
      case 3: return data.TakeLanguageMechanics;
      case 4: return data.TakeReadingComprehension;
      case 5: return data.TakeMathComputation;
      case 6: return data.TakeMathApplication;
      default: return false;
    }
  }

  getCategoryIsAssessed(data: SurveyData, categoryId: number): boolean {
    switch (categoryId) {
      case 1: return data.IsVocabularyAssessed;
      case 2: return data.IsSpellingAssessed;
      case 3: return data.IsLanguageMechanicsAssessed;
      case 4: return data.IsReadingComprehensionAssessed;
      case 5: return data.IsMathComputationAssessed;
      case 6: return data.IsMathApplicationAssessed;
      default: return false;
    }
  }
}

