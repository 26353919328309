import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '@app/Services';
import { CustomRoles } from '@app/Models/CustomRoles';

@Injectable()
export class RoleGuard implements CanActivate {
    constructor(private auth: AuthService, public router: Router) { }

    canActivate(next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        let roles = next.data.roles as Array<string>;

        const expectedRole = [
            CustomRoles.DistrictAdministrator,
            CustomRoles.DistrictStaff,
            CustomRoles.SchoolAdministrator,
            CustomRoles.RelatedServices
        ]
        const userRole = this.auth.getRole();

        if (!roles) roles = expectedRole;

        if (!this.auth.isLoggedIn() || roles.indexOf(userRole) == -1) {
            this.router.navigate(['/login']);
            return false;
        }
        return true;
    }
}