// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#select-header {
    background: #3f51b5;
    min-width: max-content;
    flex-direction: row;
    display: flex;
}

#select-header button {
    font-size: 12px;
    margin: 1%;
    border: 1px solid white;
    color: white;
}

#select-header input {
    flex-grow: 1;
    font-size: 14px;
    margin: calc(1% + 1px) 1%;
}

#singleSelectField {
    width: 100%;
}

#singleSelectField ::ng-deep .mat-form-field-flex {
    background-color: rgba(0, 0, 0, .04);
    border-radius: 4px 4px 0 0;
    padding: 0.75em 0.75em 0 0.75em;
}

#singleSelectField ::ng-deep .mat-form-field-infix {
    padding: 0.25em 0 0.75em 0;
}

#singleSelectField ::ng-deep .mat-select-disabled .mat-select-trigger, ::ng-deep .mat-form-field-disabled {
    cursor: not-allowed !important;
}

::ng-deep mat-option.noResult .mat-pseudo-checkbox-disabled {
    display: none !important;
}`, "",{"version":3,"sources":["webpack://./src/app/CustomComponents/single-select/single-select.component.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,UAAU;IACV,uBAAuB;IACvB,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,yBAAyB;AAC7B;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,oCAAoC;IACpC,0BAA0B;IAC1B,+BAA+B;AACnC;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,wBAAwB;AAC5B","sourcesContent":["#select-header {\r\n    background: #3f51b5;\r\n    min-width: max-content;\r\n    flex-direction: row;\r\n    display: flex;\r\n}\r\n\r\n#select-header button {\r\n    font-size: 12px;\r\n    margin: 1%;\r\n    border: 1px solid white;\r\n    color: white;\r\n}\r\n\r\n#select-header input {\r\n    flex-grow: 1;\r\n    font-size: 14px;\r\n    margin: calc(1% + 1px) 1%;\r\n}\r\n\r\n#singleSelectField {\r\n    width: 100%;\r\n}\r\n\r\n#singleSelectField ::ng-deep .mat-form-field-flex {\r\n    background-color: rgba(0, 0, 0, .04);\r\n    border-radius: 4px 4px 0 0;\r\n    padding: 0.75em 0.75em 0 0.75em;\r\n}\r\n\r\n#singleSelectField ::ng-deep .mat-form-field-infix {\r\n    padding: 0.25em 0 0.75em 0;\r\n}\r\n\r\n#singleSelectField ::ng-deep .mat-select-disabled .mat-select-trigger, ::ng-deep .mat-form-field-disabled {\r\n    cursor: not-allowed !important;\r\n}\r\n\r\n::ng-deep mat-option.noResult .mat-pseudo-checkbox-disabled {\r\n    display: none !important;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
