;import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route, Router } from '@angular/router';
import { Observable, of } from 'rxjs';


@Injectable()
export class SelectivePreloadingStrategy implements PreloadingStrategy {
    //preloadedModules: any[] = [];
    constructor(private router: Router) { }

    preload(route: Route, load: () => Observable<any>): Observable<any> {

        var path = this.router;
        if (route.data && route.data['preload'] && route.data['myvar']) {

            if (path.url.split('/')[1] == "accounts") {
                if (route.data['myvar'] == "appUser" || route.data['myvar'] == "assessmentUser") {
                    //console.log("blocked, only accounts " + route.data['myvar']); 
                    return of(null);
                }
            }
            if (path.url.split('/')[1] == "assessment") {
                if (route.data['myvar'] == "appAdmin" || route.data['myvar'] == "appUser") {
                    //console.log("blocked, only assessment " + route.data['myvar']);
                    return of(null);
                }
            }
            else if (['school', 'staff', 'students', 'uaperiods', 'progress-monitoring', 'resources', 'accounts'].includes(path.url.split('/')[1])) {
                if (route.data['myvar'] == "appAdmin" || route.data['myvar'] == "assessmentUser") {
                    //console.log("blocked, only users " + route.data['myvar']);
                    return of(null);
                }
            }
            else {
                //console.log("blocked all" + route.data['myvar']);
                return of(null);
            };

            //console.log("passed " + route.data['myvar']);
            return load();
        }
        else {
            return of(null);
        }
    }
}