import { Component, OnInit, } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations'
import { UsersService } from '@app/Services';

@Component({
    selector: 'app-reset',
    templateUrl: './reset.component.html',
    styleUrls: ['./reset.component.css'],
    animations: [
        trigger(
            'myAnimation',
            [
                transition(
                    ':enter', [
                    style({ opacity: 0 }),
                    animate('500ms', style({ 'opacity': 1 }))
                ]
                )]
        )
    ]
})
export class ResetComponent implements OnInit {
    model: any = { "Email": '' };

    message = "";
    showResponse = false;
    successfulEmail = false;

    constructor(private usersService: UsersService) { }

    ngOnInit() {
    }

    reset() {
        this.usersService.requestResetPassword(this.model).subscribe(
            result => {
                this.message = result;
                this.successfulEmail = true;
                this.showResponse = true;
            },
            error => {
                if ((error.status == 400 || error.status == 500) && typeof error.error == "string" && error.error.length < 2000) {
                    this.message = error.error;
                }
                else {
                    this.message = "An error occured, try again later."
                }
                this.successfulEmail = false;
                this.showResponse = true;
            }
        )
    }

}
