// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main_content>div {
    width: 90%;
}

.alert {
    margin: 1rem 0 0;
}

.actions {
    width: 177px;
    display: table;
    float: left;
    margin-top: 50px;
}

.actions button {
    float: left;
    margin: 5px 0;
}

.staff_data {
    width: calc(100% - 265px);
    margin-left: 30px;
    margin-top: 50px;
    padding-bottom: 10px;
    display: table;
    float: left;
    text-align: left;
}

span {
    float: left;
    font-size: 17px;
    margin: 5px 0;
    text-align: left;
    display: flex;
    width: 100%;
}

strong {
    font-size: 18px;
    width: 180px;
}

h2 {
    font-weight: bold;
}

#licensesTable {
    margin-left: 20px;
}

#licensesTable {
    border: 3px solid rgba(2, 15, 152, 0.83);
}

#licensesTable tr>* {
    padding: 3px 5px;
    border: 1px solid rgba(2, 15, 152, 0.83);
}

#licensesTable td:nth-child(1) {
    text-align: left;
}

#licensesTable td:nth-child(n+2) {
    text-align: right;
}`, "",{"version":3,"sources":["webpack://./src/app/SharedModules/shared/staff/details-staff/details-staff.component.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,cAAc;IACd,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,yBAAyB;IACzB,iBAAiB;IACjB,gBAAgB;IAChB,oBAAoB;IACpB,cAAc;IACd,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,eAAe;IACf,aAAa;IACb,gBAAgB;IAChB,aAAa;IACb,WAAW;AACf;;AAEA;IACI,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,wCAAwC;AAC5C;;AAEA;IACI,gBAAgB;IAChB,wCAAwC;AAC5C;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".main_content>div {\r\n    width: 90%;\r\n}\r\n\r\n.alert {\r\n    margin: 1rem 0 0;\r\n}\r\n\r\n.actions {\r\n    width: 177px;\r\n    display: table;\r\n    float: left;\r\n    margin-top: 50px;\r\n}\r\n\r\n.actions button {\r\n    float: left;\r\n    margin: 5px 0;\r\n}\r\n\r\n.staff_data {\r\n    width: calc(100% - 265px);\r\n    margin-left: 30px;\r\n    margin-top: 50px;\r\n    padding-bottom: 10px;\r\n    display: table;\r\n    float: left;\r\n    text-align: left;\r\n}\r\n\r\nspan {\r\n    float: left;\r\n    font-size: 17px;\r\n    margin: 5px 0;\r\n    text-align: left;\r\n    display: flex;\r\n    width: 100%;\r\n}\r\n\r\nstrong {\r\n    font-size: 18px;\r\n    width: 180px;\r\n}\r\n\r\nh2 {\r\n    font-weight: bold;\r\n}\r\n\r\n#licensesTable {\r\n    margin-left: 20px;\r\n}\r\n\r\n#licensesTable {\r\n    border: 3px solid rgba(2, 15, 152, 0.83);\r\n}\r\n\r\n#licensesTable tr>* {\r\n    padding: 3px 5px;\r\n    border: 1px solid rgba(2, 15, 152, 0.83);\r\n}\r\n\r\n#licensesTable td:nth-child(1) {\r\n    text-align: left;\r\n}\r\n\r\n#licensesTable td:nth-child(n+2) {\r\n    text-align: right;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
