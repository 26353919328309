import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { 
  VerbalQuestionGroup, SurveyStudentAnswer, MathQuestion, SurveyData, StudentAuthorization, ScoringData, PDFData, 
  StudentSurveyResult, ImportRawDataCountersDTO 
} from '@app/Models/Survey';
import { 
  ScoringAnswers, ScoringEditDTO 
} from '@app/Models/Assessment';

@Injectable({
  providedIn: 'root'
})
export class SurveyService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  getVerbalData(assessmentId: number, token: string): Observable<VerbalQuestionGroup[]> {
    return this.http.get<VerbalQuestionGroup[]>(`${this.baseUrl}api/Survey/GetVerbalQuestions/${assessmentId}/${token}`).pipe(
      shareReplay()
    );
  }

  submitVerbalAnswers(auth: StudentAuthorization, answers: SurveyStudentAnswer[]): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}api/Survey/SubmitVerbalAnswers`, { auth, answers }).pipe(
      shareReplay()
    );
  }

  getMathData(assessmentId: number, token: string): Observable<MathQuestion[]> {
    return this.http.get<MathQuestion[]>(`${this.baseUrl}api/Survey/GetMathQuestions/${assessmentId}/${token}`).pipe(
      shareReplay()
    );
  }

  submitMathAnswers(auth: StudentAuthorization, answers: SurveyStudentAnswer[]): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}api/Survey/SubmitMathAnswers`, { auth, answers }).pipe(
      shareReplay()
    );
  }

  getStudentSurveyData(assessmentId: number, token: string): Observable<SurveyData> {
    return this.http.get<SurveyData>(`${this.baseUrl}api/Survey/StudentData/${assessmentId}/${token}`).pipe(
      shareReplay()
    );
  }

  getScoringData(studentId: number): Observable<ScoringData> {
    return this.http.get<ScoringData>(`${this.baseUrl}api/Survey/GetScoringData/${studentId}`).pipe(
      shareReplay()
    );
  }

  getImportRawData(periodId: number): Observable<ImportRawDataCountersDTO> {
    return this.http.get<ImportRawDataCountersDTO>(`${this.baseUrl}api/Survey/GetImportRawDataInfo/${periodId}`).pipe(
      shareReplay()
    );
  }

  submitScoringData(data: ScoringAnswers): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}api/Survey/SubmitScoring`, data).pipe(
      shareReplay()
    );
  }

  getScoringAnswers(assessmentId: number): Observable<ScoringEditDTO> {
    return this.http.get<ScoringEditDTO>(`${this.baseUrl}api/Survey/GetScoringAnswers/${assessmentId}`).pipe(
      shareReplay()
    );
  }

  getSurveyStudentResults(studentId: number): Observable<StudentSurveyResult[]> {
    return this.http.get<StudentSurveyResult[]>(`${this.baseUrl}api/Survey/GetStudentSurveyResults/${studentId}`).pipe(
      shareReplay()
    );
  }

  getSurveyPdfData(assessmentId: number): Observable<PDFData> {
    return this.http.get<PDFData>(`${this.baseUrl}api/Survey/GetPdfData/${assessmentId}`).pipe(
      shareReplay()
    );
  }

  scoreTest(assessmentId: number, testCode: number): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}api/Survey/ScoreTest/${assessmentId}/${testCode}`).pipe(
      shareReplay()
    );
  }
}
