import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AuthService, UsersService } from '@app/Services';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { ChangePasswordComponent } from '@app/SharedModules/shared/staff/change-password/change-password.component';
import { CustomRoles } from '@app/Models/CustomRoles';

@Component({
  selector: 'app-test-nav',
  templateUrl: './test-nav.component.html',
  styleUrls: ['./test-nav.component.css']
})
export class TestNavComponent implements OnInit {
  isShown: boolean = false;

  selectedNav = 1;

  allow1234: boolean = false;
  isSuperadmin: boolean;
  fullName = "";

  constructor(private router: Router, private loc: Location, private authService: AuthService, private usersService: UsersService, public dialog: MatDialog) {

    this.isSuperadmin = this.authService.isAdmin();
    var role = this.authService.getRole();
    this.allow1234 = (role === CustomRoles.DistrictAdministrator || role === CustomRoles.DistrictStaff || role === CustomRoles.SchoolAdministrator || role === CustomRoles.RelatedServices);

    var path = loc.path().split("/");
    if (path[1] == "school") this.selectedNav = 1;
    else if (path[1] == "staff") this.selectedNav = 2;
    else if (path[1] == "students") this.selectedNav = 3;
    else if (path[1] == "uaperiods") this.selectedNav = 4;
    else if (path[1] == "progress-monitoring") this.selectedNav = 6;
    else if (path[1] == "resources") this.selectedNav = 5;



    else if (path[1] == "accounts") {
      if (path[2] == "clients") this.selectedNav = 8;
    }
    else if (path[1] == "user") {
      if (path[2] == "myAccount") this.selectedNav = 9;
    }
  }


  ngOnInit() {
    this.usersService.getAccountName().subscribe(
      result => this.fullName = result,
      error => this.fullName = ""
    )

  }

  logout() {
    this.authService.logout();
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      width: '600px'
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.usersService.changePassword(data).subscribe(
          result => {
            this.authService.alertSuccess("Successfully changed password.")
          },
          error => this.authService.alertError("Could not change password.")
        )
      }
    });

  }

}
