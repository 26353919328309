import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AccountLicensesDTO, AccountNewLicensesDTO, AccountRequestLicensesDTO } from "@app/Models/Account";
import { StaffAccountLicensesDTO } from "@app/Models/Staff";
import { AccountsService, AuthService, StaffService } from "@app/Services";

@Component({
    selector: 'request-licenses-modal',
    templateUrl: './request-licenses-modal.html',
    styleUrls: ['./request-licenses-modal.css']
})
export class RequestLicensesModal implements OnInit {
    account: StaffAccountLicensesDTO = {} as StaffAccountLicensesDTO;
    accountNew: AccountRequestLicensesDTO = { Verbal: 0, Math: 0, Comp: 0 } as AccountRequestLicensesDTO;

    constructor(@Inject(MAT_DIALOG_DATA) public data: StaffAccountLicensesDTO, public thisDialogRef: MatDialogRef<RequestLicensesModal>, @Inject(MAT_DIALOG_DATA) public accountId: number) {
        this.account = data
    }

    ngOnInit() {
        var elements = document.getElementsByTagName("input");
        for (var i = 0; i < 3; i++) {
            elements[i].addEventListener("input", function (e) {
                (<HTMLInputElement>e.target).value = parseInt((<HTMLInputElement>e.target).value).toString();
                if ((<HTMLInputElement>e.target).value == '') (<HTMLInputElement>e.target).value = '0';
            });
        }
    }

    getAbsNumber(num) {
        return Math.abs(num)
    }

    onCloseConfirm() {
        this.thisDialogRef.close(this.accountNew);
    }

    onCloseCancel() {
        this.thisDialogRef.close(false);
    }
}

