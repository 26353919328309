// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title {
    width:100%;
    border-bottom: 3px solid rgba(2, 15, 152, 0.83);
    text-transform: uppercase;
    text-align: center;
}
.title h2 {
    margin-bottom: 0;
    font-weight: bold;
}

mat-dialog-content{
    margin-top:30px;
    text-align: center;
}

br {
    display: block;
    margin: 8px 0;
 }


button {
    margin: 15px 5px 20px;
}
`, "",{"version":3,"sources":["webpack://./src/app/SharedModules/shared/staff/login-link-staff/login-link-staff.component.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,+CAA+C;IAC/C,yBAAyB;IACzB,kBAAkB;AACtB;AACA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,aAAa;CAChB;;;AAGD;IACI,qBAAqB;AACzB","sourcesContent":[".title {\r\n    width:100%;\r\n    border-bottom: 3px solid rgba(2, 15, 152, 0.83);\r\n    text-transform: uppercase;\r\n    text-align: center;\r\n}\r\n.title h2 {\r\n    margin-bottom: 0;\r\n    font-weight: bold;\r\n}\r\n\r\nmat-dialog-content{\r\n    margin-top:30px;\r\n    text-align: center;\r\n}\r\n\r\nbr {\r\n    display: block;\r\n    margin: 8px 0;\r\n }\r\n\r\n\r\nbutton {\r\n    margin: 15px 5px 20px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
