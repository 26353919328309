import { Component, OnInit } from '@angular/core';

import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { LoginLinkStaffComponent } from '../login-link-staff/login-link-staff.component';
import { DeactivateMemberStaffComponent } from '../deactivate-member-staff/deactivate-member-staff.component';
import { ActivateMemberStaffComponent } from '../activate-member-staff/activate-member-staff.component';
import { ChangePasswordComponent } from '../change-password/change-password.component';

import { ActivatedRoute, Router } from '@angular/router';
import { trigger, transition, style, animate, state } from '@angular/animations'
import { AuthService, StaffService, UsersService } from '@app/Services';
import { StaffAccountLicensesDTO, StaffDetailsDTO } from '@app/Models/Staff';

import { Location } from '@angular/common';
import { RequestLicensesModal } from '../request-licenses/request-licenses-modal';
import { CustomRoles } from '@app/Models/CustomRoles';


@Component({
    selector: 'app-details-staff',
    templateUrl: './details-staff.component.html',
    styleUrls: ['./details-staff.component.css'],
    animations: [
        trigger(
            'myAnimation',
            [
                transition(
                    ':enter', [
                    style({ opacity: 0 }),
                    animate('500ms', style({ 'opacity': 1 }))
                ]
                )]
        )
    ]
})
export class DetailsStaffComponent implements OnInit {

    staff: any = {};
    account: StaffAccountLicensesDTO;
    myAcc = false;
    successfulDeactivation = false;
    successfulActivation = false;

    alertPopup = { 'text': '', 'visible': false, 'class': 'success' };

    hideActions = false;
    allow1 = false;
    allow1234 = false;

    constructor(private location: Location, private authService: AuthService, private staffService: StaffService, private usersService: UsersService, public dialog: MatDialog, private route: ActivatedRoute, private router: Router) {
        var role = this.authService.getRole();
        this.allow1 = (role === CustomRoles.DistrictAdministrator);
        this.allow1234 = (role === CustomRoles.DistrictAdministrator || role === CustomRoles.DistrictStaff || role === CustomRoles.SchoolAdministrator || role === CustomRoles.RelatedServices);

        var id = +this.route.snapshot.paramMap.get('id');
        if (!!id) {
            this.hideActions = true;
            this.staffService.getStaffDetailsById(id).subscribe(
                (staff: StaffDetailsDTO) => {
                    this.staff = staff;
                    this.hideActions = false;
                },
                error => this.router.navigate(['/staff']),
            )
        }
        else if (id == 0) {
            this.myAcc = true;
            this.staffService.getStaffOwnDetails().subscribe(
                (staff: StaffDetailsDTO) => {
                    this.staff = staff;
                    if (this.allow1) {
                        this.staffService.getAccountLicenses().subscribe(
                            (licenses: StaffAccountLicensesDTO) => {
                                this.account = licenses;
                            },
                            error => this.authService.alertError("An error occured while requesting your licenses.")
                        )
                    }
                }
            )
        }
        else {
            this.router.navigate(['/staff']);
        }
    }

    sendSetupAccountEmail() {
        this.alertPopup = { 'text': '', 'visible': false, 'class': 'success' };
        this.staffService.sendSetupAccountEmail(this.staff['Id']).subscribe(
            result => {
                this.alertPopup = { 'text': `An email was sent successfully to staff member ${this.staff.Surname} ${this.staff.Name}.`, 'visible': true, 'class': 'success' };
            },
            error => {
                this.alertPopup = { 'text': 'An email could not be sent to staff member.', 'visible': true, 'class': 'danger' };
            }
        );
    }

    ngOnInit() { }

    goBack() {
        this.location.back();
    }

    openDialog(dial) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        if (dial == "sendlink") {
            const dialogRef = this.dialog.open(LoginLinkStaffComponent, {
                width: 'auto',
                data: true
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.sendSetupAccountEmail();
                }
            });
        }
        else if (dial == "deactivate") {
            const dialogRef = this.dialog.open(DeactivateMemberStaffComponent, {
                width: '600px',
                data: this.staff['Id']
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.staff['Active'] = false;
                    this.successfulDeactivation = true;
                    this.successfulActivation = false;
                }
            });
        }
        else if (dial == "activate") {
            const dialogRef = this.dialog.open(ActivateMemberStaffComponent, {
                width: '600px',
                data: this.staff['Id']
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.staff['Active'] = true;
                    this.successfulDeactivation = false;
                    this.successfulActivation = true;
                }
            });
        }
        else if (dial == "changePassword") {
            const dialogRef = this.dialog.open(ChangePasswordComponent, {
                width: '600px'
            });
            dialogRef.afterClosed().subscribe(data => {
                if (data) {
                    this.alertPopup = { 'text': '', 'visible': false, 'class': 'success' };
                    this.usersService.changePassword(data).subscribe(
                        result => {
                            this.alertPopup = { 'text': 'Successfully changed password.', 'visible': true, 'class': 'success' };
                        },
                        error => {
                            this.alertPopup = { 'text': 'Could not change password.', 'visible': true, 'class': 'danger' };
                        }
                    )
                }
            });
        }
        else if (dial == "requestLicenses") {
            const dialogRef = this.dialog.open(RequestLicensesModal, {
                width: '600px',
                data: this.account
            });
            dialogRef.afterClosed().subscribe(newAccountLicenses => {
                if (newAccountLicenses) {
                    if (newAccountLicenses['Verbal'] == null) newAccountLicenses['Verbal'] = 0;
                    if (newAccountLicenses['Math'] == null) newAccountLicenses['Math'] = 0;
                    if (newAccountLicenses['Comp'] == null) newAccountLicenses['Comp'] = 0;

                    if (newAccountLicenses['Verbal'] > 0 || newAccountLicenses['Math'] > 0 || newAccountLicenses['Comp'] > 0) {
                        this.staffService.requestAccountLicenses(newAccountLicenses).subscribe(
                            (result) => {
                                this.authService.alertSuccess("An email was successfully sent for requesting licenses.")
                            },
                            error => {
                                this.authService.alertError("Error occured while sending the email, please try again later or get in touch with us if urgent.")
                            }
                        )
                    }
                    else {
                        this.authService.alertError("You have not set any licenses.")
                    }
                }
            });
        }
    }
}

/*
@Component({
    selector: 'login-to-account-modal',
    templateUrl: './login-to-account-modal.html',
    styleUrls: ['./login-to-account-modal.css',
        '../../../common_styles.css']
})
export class LoginToAccountModal implements OnInit {
    account: any = {};

    constructor(public thisDialogRef: MatDialogRef<LoginToAccountModal>, @Inject(MAT_DIALOG_DATA) public data: any) {
        this.account = data;
    }

    ngOnInit() {
    }

    onCloseConfirm() {
        this.thisDialogRef.close(true);
    }

    onCloseCancel() {
        this.thisDialogRef.close(false);
    }
}*/