import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/Services';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    model: any = { Email: '', Password: '' };
    passwordVisible = false;
    invalidLogin: boolean = false;
    invalidText: string = "";

    constructor(private router: Router, private authService: AuthService) {
        if (this.authService.isLoggedIn()) {
            if (this.authService.isAdmin()) this.router.navigate(['/accounts']);
            else this.router.navigate(['/school']);
        }
    }

    ngOnInit() {
    }

    login() {
        this.invalidLogin = false;
        this.invalidText = "";
        this.authService.login(this.model).subscribe(
            result => {
                this.invalidLogin = false;
                if (this.authService.isAdmin()) this.router.navigate(['/accounts']);
                else this.router.navigate(['/school']);
            },
            error => {
                this.invalidLogin = true;
                if ((error.status == 400 || error.status == 500) && typeof error.error == "string" && error.error.length < 2000) {
                    this.invalidText = error.error;
                }
                else {
                    this.invalidText = "An error occured, try again later."
                }
            }
        );
        //alert(JSON.stringify(this.model))
    }

}
