import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient, HttpHeaders } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AuthInterceptor } from './auth.interceptor';
import { AuthGuard } from './Guards/auth.guard';
import { SuperadminGuard } from './Guards/superadmin.guard';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { BrowserModule } from '@angular/platform-browser';

import * as Services from './Services/index';

//import { registerLocaleData } from '@angular/common';
//import localeFr from '@angular/common/locales/el';
//registerLocaleData(localeFr, 'el');

import { Globals } from '@app/globals';
import { EnsureActionModalComponent } from './CustomComponents/ensure-action-modal/ensure-action-modal.component';
import * as UserComponents from './UserComponents/index';
import { SharedModule } from './SharedModules/shared/shared.module';
import * as WebFont from 'webfontloader';

WebFont.load({
    custom: { families: ['Material Icons', 'Material Icons Outline'], }
});

import { SelectivePreloadingStrategy } from './app-selective-preloading';
import { RoleGuard } from './Guards/role.guard';
import { MatRippleModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';

@NgModule({
    declarations: [
        AppComponent,

        UserComponents.LoginComponent,
        UserComponents.ResetComponent,
        UserComponents.SetupAccountComponent,
        UserComponents.TermsComponent,
        UserComponents.TestNavComponent,

        EnsureActionModalComponent,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatRippleModule,
        SharedModule,

        AppRoutingModule,
    ],
    exports: [
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatRippleModule,
    ],
    providers: [
        HttpClientModule,
        HttpClient,
        Services.SchoolsService,
        Services.StaffService,
        Services.StudentsService,
        Services.UaPeriodService,
        Services.UsersService,
        Services.AuthService,
        Services.AccountsService,
        Services.SurveyService,
        Services.ComprehensiveService,
        Services.ProgressMonitoringService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        AuthGuard,
        SuperadminGuard,
        RoleGuard,
        Globals,

        SelectivePreloadingStrategy,
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { subscriptSizing: 'dynamic' } },
        { provide: LocationStrategy, useClass: HashLocationStrategy }
    ],
    bootstrap: [AppComponent],

})
export class AppModule { }
