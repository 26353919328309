import { Component, OnInit } from '@angular/core';
import { UsersService, AuthService } from '@app/Services';
import { Router } from '@angular/router';

@Component({
    selector: 'app-terms',
    templateUrl: './terms.component.html',
    styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {

    constructor(private usersService: UsersService, private authService: AuthService, private router: Router) { }

    ngOnInit() {
        if (!this.authService.isLoggedIn()) {
            this.router.navigate(['/login']);
        }
    }

    submit() {
        this.usersService.setAgreedOnTerms().subscribe(
            result => {
                if (this.authService.isLoggedIn())
                    this.router.navigate(['/school']);
            },
            errors => {
                this.authService.alertError("An error occured while accepting terms, please go back to login page.");
            }
        );
    }

    tryLogout() {
        if (this.authService.isLoggedIn()) this.authService.logout();
    }

}
