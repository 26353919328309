import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-login-link-staff',
    templateUrl: './login-link-staff.component.html',
    styleUrls: ['./login-link-staff.component.css']
})
export class LoginLinkStaffComponent implements OnInit {
    firstTime = true;

    constructor(public thisDialogRef: MatDialogRef<LoginLinkStaffComponent>, @Inject(MAT_DIALOG_DATA) public data: boolean) {
        this.firstTime = data;
    }

    ngOnInit() {
    }

    onCloseConfirm() {
        this.thisDialogRef.close(true);
    }

    onCloseCancel() {
        this.thisDialogRef.close(false);
    }

}
