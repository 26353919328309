import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { StaffService, AuthService } from '@app/Services';
import { StudentFullNameDTO } from '@app/Models/student';

@Component({
    selector: 'app-deactivate-member-staff',
    templateUrl: './deactivate-member-staff.component.html',
    styleUrls: ['./deactivate-member-staff.component.css']
})
export class DeactivateMemberStaffComponent implements OnInit {

    staffId: number = 0;

    constructor(private staffService: StaffService, private authService: AuthService, public thisDialogRef: MatDialogRef<DeactivateMemberStaffComponent>, @Inject(MAT_DIALOG_DATA) public data: number) { }

    ngOnInit() {
        this.staffId = this.data;
    }

    onCloseConfirm() {
        this.staffService.deactivateStaff(this.staffId).subscribe(
            result => this.thisDialogRef.close(true),
            error => this.authService.alertError("An error occured while deactivating staff member.")
        )
    }

    onCloseCancel() {
        this.thisDialogRef.close(false);
    }
}
