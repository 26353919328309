import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { 
  SchoolMainDTO, SchoolDetailsDTO, SchoolEditDTO, SchoolNameDTO, SchoolActiveDTO, 
  SchoolStaffDTO, SchoolMemberDTO 
} from '@app/Models/School';
import { StudentMainDTO } from '@app/Models/student';

@Injectable({
  providedIn: 'root'
})
export class SchoolsService {
  schools = [];
  id = this.schools.length + 1;
  schoolAdded = '';

  countries = [
    { Id: "US", Name: "United States" },
    { Id: "CA", Name: "Canada" }
  ];
  usStates = [
    { Id: "AL", Name: "Alabama" }, { Id: "AK", Name: "Alaska" }, { Id: "AZ", Name: "Arizona" },
    { Id: "AR", Name: "Arkansas" }, { Id: "CA", Name: "California" }, { Id: "CO", Name: "Colorado" },
    { Id: "CT", Name: "Connecticut" }, { Id: "DE", Name: "Delaware" }, { Id: "FL", Name: "Florida" },
    { Id: "GA", Name: "Georgia" }, { Id: "HI", Name: "Hawaii" }, { Id: "ID", Name: "Idaho" },
    { Id: "IL", Name: "Illinois" }, { Id: "IN", Name: "Indiana" }, { Id: "IA", Name: "Iowa" },
    { Id: "KS", Name: "Kansas" }, { Id: "KY", Name: "Kentucky" }, { Id: "LA", Name: "Louisiana" },
    { Id: "ME", Name: "Maine" }, { Id: "MD", Name: "Maryland" }, { Id: "MA", Name: "Massachusetts" },
    { Id: "MI", Name: "Michigan" }, { Id: "MN", Name: "Minnesota" }, { Id: "MS", Name: "Mississippi" },
    { Id: "MO", Name: "Missouri" }, { Id: "MT", Name: "Montana" }, { Id: "NE", Name: "Nebraska" },
    { Id: "NV", Name: "Nevada" }, { Id: "NH", Name: "New Hampshire" }, { Id: "NJ", Name: "New Jersey" },
    { Id: "NM", Name: "New Mexico" }, { Id: "NY", Name: "New York" }, { Id: "NC", Name: "North Carolina" },
    { Id: "ND", Name: "North Dakota" }, { Id: "OH", Name: "Ohio" }, { Id: "OK", Name: "Oklahoma" },
    { Id: "OR", Name: "Oregon" }, { Id: "PA", Name: "Pennsylvania" }, { Id: "RI", Name: "Rhode Island" },
    { Id: "SC", Name: "South Carolina" }, { Id: "SD", Name: "South Dakota" }, { Id: "TN", Name: "Tennessee" },
    { Id: "TX", Name: "Texas" }, { Id: "UT", Name: "Utah" }, { Id: "VT", Name: "Vermont" },
    { Id: "VA", Name: "Virginia" }, { Id: "WA", Name: "Washington" }, { Id: "WV", Name: "West Virginia" },
    { Id: "WI", Name: "Wisconsin" }, { Id: "WY", Name: "Wyoming" }
  ];

  caStates = [
    { Id: "AB", Name: "Alberta" }, { Id: "BC", Name: "British Columbia" }, { Id: "MB", Name: "Manitoba" },
    { Id: "NB", Name: "New Brunswick" }, { Id: "NL", Name: "Newfoundland and Labrador" },
    { Id: "NT", Name: "Northwest Territories" }, { Id: "NS", Name: "Nova Scotia" }, { Id: "NU", Name: "Nunavut" },
    { Id: "ON", Name: "Ontario" }, { Id: "PE", Name: "Prince Edward Island" }, { Id: "QC", Name: "Quebec" },
    { Id: "SK", Name: "Saskatchewan" }, { Id: "YT", Name: "Yukon" }
  ];

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  setSchoolAdded(name: string): void {
    this.schoolAdded = name;
  }

  getSchoolAdded(): string {
    return this.schoolAdded;
  }

  clearSchoolAdded(): void {
    this.schoolAdded = '';
  }

  isSchoolAdded(): boolean {
    return !!this.schoolAdded;
  }

  getCountries(): { Id: string, Name: string }[] {
    return this.countries;
  }

  getUSStates(): { Id: string, Name: string }[] {
    return this.usStates;
  }

  getCAStates(): { Id: string, Name: string }[] {
    return this.caStates;
  }

  getSchoolsMain(): Observable<SchoolMainDTO[]> {
    return this.http.get<SchoolMainDTO[]>(`${this.baseUrl}api/Schools/GetAllSchoolsMain`).pipe(
      shareReplay()
    );
  }

  getAllSchoolsNames(): Observable<SchoolNameDTO[]> {
    return this.http.get<SchoolNameDTO[]>(`${this.baseUrl}api/Schools/GetAllSchoolsNames`).pipe(
      shareReplay()
    );
  }

  getActiveSchoolsNames(): Observable<SchoolNameDTO[]> {
    return this.http.get<SchoolNameDTO[]>(`${this.baseUrl}api/Schools/GetActiveSchoolsNames`).pipe(
      shareReplay()
    );
  }

  getSchoolDetailsById(id: number): Observable<SchoolDetailsDTO> {
    return this.http.get<SchoolDetailsDTO>(`${this.baseUrl}api/Schools/GetSchoolDetailsById/${id}`).pipe(
      shareReplay()
    );
  }

  getSchoolByIdEdit(id: number): Observable<SchoolEditDTO> {
    return this.http.get<SchoolEditDTO>(`${this.baseUrl}api/Schools/GetSchoolByIdEdit/${id}`).pipe(
      shareReplay()
    );
  }

  getSchoolsActiveState(): Observable<SchoolActiveDTO[]> {
    return this.http.get<SchoolActiveDTO[]>(`${this.baseUrl}api/Schools/GetSchoolsActiveState`).pipe(
      shareReplay()
    );
  }

  importSchools(schools: any[]): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}api/Schools/Import`, schools).pipe(
      shareReplay()
    );
  }

  addSchool(school: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}api/Schools/Add`, school).pipe(
      shareReplay()
    );
  }

  updateSchool(school: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}api/Schools/Update`, school).pipe(
      shareReplay()
    );
  }

  deactivateSchool(id: number): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}api/Schools/DeactivateSchool`, { id }).pipe(
      shareReplay()
    );
  }

  activateSchool(id: number): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}api/Schools/ActivateSchool`, { id }).pipe(
      shareReplay()
    );
  }

  deactivateAllStaff(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}api/Schools/DeactivateAllStaff`).pipe(
      shareReplay()
    );
  }

  deactivateAllStudents(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}api/Schools/DeactivateAllStudents`).pipe(
      shareReplay()
    );
  }

  deactivateStaff(id: number): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}api/Schools/DeactivateStaff`, { id }).pipe(
      shareReplay()
    );
  }

  deactivateStudents(id: number): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}api/Schools/DeactivateStudents`, { id }).pipe(
      shareReplay()
    );
  }

  getSchoolImage(schoolId: number) {
    return this.http.request(new HttpRequest('GET', this.baseUrl + `api/Files/GetSchoolImage/${schoolId}`, null, { reportProgress: true, responseType: 'blob' })).toPromise();
  }

  getSchoolMembers(id: number): Observable<SchoolMemberDTO[]> {
    return this.http.get<SchoolMemberDTO[]>(`${this.baseUrl}api/Schools/GetAllMembers/${id}`).pipe(
      shareReplay()
    );
  }

  getSchoolsStudents(schoolsIds: number[]): Observable<StudentMainDTO[]> {
    return this.http.post<StudentMainDTO[]>(`${this.baseUrl}api/Students/GetSchoolsStudents`, schoolsIds).pipe(
      shareReplay()
    );
  }

  getSchoolsStaff(ids: number[]): Observable<SchoolStaffDTO[]> {
    return this.http.post<SchoolStaffDTO[]>(`${this.baseUrl}api/Schools/GetAllStaff`, ids).pipe(
      shareReplay()
    );
  }

  getActiveSchoolStaff(ids: number[]): Observable<SchoolStaffDTO[]> {
    return this.http.post<SchoolStaffDTO[]>(`${this.baseUrl}api/Schools/GetActiveStaff`, ids).pipe(
      shareReplay()
    );
  }
}