import { SafeHtml } from '@angular/platform-browser';
import { MathQuestionTest, SurveyQuestionAnswer } from './Survey';

export interface ComprVerbalQuestionGroupTest {
    IsPassage: boolean;
    GroupDescription: string;
    GroupPassage: string;
    Questions: ComprVerbalQuestion[];
}

export interface ComprVerbalQuestionGroup {
    IsPassage: boolean;
    GroupDescription: string;
    GroupPassage: string | SafeHtml;
    PassageImage: number[];
    Questions: ComprVerbalQuestion[];
}
export class ComprVerbalQuestion {
    QuestionId: string;
    QuestionText: string;
    QuestionImage: number[];
    CorrectAnswer: string;
    Answers: ComprAnswer[];
    SelectedAnswer: string;

    constructor(questionId, questionText, correctAnswer, answers, questionImage = []) {
        this.QuestionId = questionId;
        this.QuestionText = questionText;
        this.QuestionImage = questionImage;
        this.CorrectAnswer = correctAnswer;
        this.SelectedAnswer = null;
        this.Answers = answers;
    }
}
export class ComprAnswer {
    AnswerId: string;
    Answer: string;

    constructor(answerId, answer) {
        this.AnswerId = answerId;
        this.Answer = answer;
    }
}

export interface ComprVerbalCategoryInfo {
    Category: string;
    Time: number;
    Directions: string;
    Samples: ComprVerbalQuestionGroupTest[];
}

export interface ComprMathCategoryInfo {
    Category: string;
    Time: number;
    Directions: string;
    Samples: MathQuestionTest[];
}

export class ComprStudentAnswer {
    QuestionId: string;
    AnswerId: string;

    constructor(question, answer) {
        this.QuestionId = question;
        this.AnswerId = answer;
    }
}

export interface ComprStudentAuthorization {
    CategoryId: number;
    AssessmentId: number;
    Token: string;
}

export interface ComprStudentResult {
    AssessmentId: number;
    NeedsScoring: boolean;
    PeriodName: string;
    StartDate: Date;
    EndDate: Date;
    IsCurrent: boolean;
    Level: string;
    Form: string;
    Timed: boolean;
    SentTestDate: Date;
    AssessmentDate: string;
    SchoolName: string;
    Link: string;

    VocabularyNorm: ComprNormDTO;
    SpellingNorm: ComprNormDTO;
    LanguageMechanicsNorm: ComprNormDTO;
    ReadingComprehensionNorm: ComprNormDTO;
    MathComputationNorm: ComprNormDTO;
    MathApplicationNorm: ComprNormDTO;

    ReadingTotal: ComprNormDTO;
    WrittenLanguageTotal: ComprNormDTO;
    MathTotal: ComprNormDTO;

    LoVocabulary: ComprLearningObjectiveResults[];
    LoSpelling: ComprLearningObjectiveResults[];
    LoLanguageMechanics: ComprLearningObjectiveResults[];
    LoReadingComprehension: ComprLearningObjectiveResults[];
    LoMathComputation: ComprLearningObjectiveResults[];
    LoMathApplication: ComprLearningObjectiveResults[];
}

export interface ComprNormDTO {
    StandardScore: string;
    Percentile: string;
    ConfidenceInterval: string;

    GradeEquivalent: string;
    AgeEquivalent: string;
}


export interface ComprLearningObjectiveResults {
    Title: string;
    Percentile: string;
    Classification: string;
    IsSupergroup: boolean;
}


export class ScoringComprData {
    VocabularyQuestions: SurveyQuestionAnswer[];
    ReadingComprehensionQuestions: SurveyQuestionAnswer[];
    SpellingQuestions: SurveyQuestionAnswer[];
    LanguageMechanicsQuestions: SurveyQuestionAnswer[];
    MathComputationQuestions: SurveyQuestionAnswer[];
    MathApplicationQuestions: SurveyQuestionAnswer[];

    constructor() {
        this.VocabularyQuestions = [];
        this.ReadingComprehensionQuestions = [];
        this.SpellingQuestions = [];
        this.LanguageMechanicsQuestions = [];
        this.MathComputationQuestions = [];
        this.MathApplicationQuestions = [];
    }
}
