import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { StaffService } from '@app/Services/staff.service';
import { AuthService } from '@app/Services';

@Component({
    selector: 'app-activate-member-staff',
    templateUrl: './activate-member-staff.component.html',
    styleUrls: ['./activate-member-staff.component.css']
})
export class ActivateMemberStaffComponent implements OnInit {

    adminId: number = 0;

    constructor(private authService: AuthService, private staffService: StaffService, public thisDialogRef: MatDialogRef<ActivateMemberStaffComponent>, @Inject(MAT_DIALOG_DATA) public data: number) { }

    ngOnInit() {
        this.adminId = this.data;
    }

    onCloseConfirm() {
        this.staffService.activateStaff(this.adminId).subscribe(
            result => this.thisDialogRef.close(true),
            error => this.authService.alertError("An error occured while activating the staff member.")
        )
    }

    onCloseCancel() {
        this.thisDialogRef.close(false);
    }
}
