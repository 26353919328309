import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { 
  UAPeriodDTO, EmailRequestDTO, UAPeriodDetailsDTO, UAPeriodEditDTO, StudentLinkDTO, UAPeriodSchoolEditDTO, 
  SchoolPeriodEditDataDTO, UAPeriodMainDTO 
} from '../Models/UAPeriod';

@Injectable({
  providedIn: 'root'
})
export class UaPeriodService {

  private apiUrl = 'api/UAPeriod/';

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  private getUrl(endpoint: string): string {
    return `${this.baseUrl}${this.apiUrl}${endpoint}`;
  }

  getUAPeriodsMain(): Observable<UAPeriodMainDTO[]> {
    return this.http.get<UAPeriodMainDTO[]>(this.getUrl('GetAllUAPeriodsMain')).pipe(
      shareReplay()
    );
  }

  getUAPeriodByIdEdit(id: number): Observable<UAPeriodEditDTO> {
    return this.http.get<UAPeriodEditDTO>(this.getUrl(`GetUAPeriodByIdEdit/${id}`)).pipe(
      shareReplay()
    );
  }

  getUAPeriodDetailsById(id: number): Observable<UAPeriodDetailsDTO> {
    return this.http.get<UAPeriodDetailsDTO>(this.getUrl(`GetUAPeriodDetailsById/${id}`)).pipe(
      shareReplay()
    );
  }

  getPeriodEmailRequests(id: number): Observable<EmailRequestDTO[]> {
    return this.http.get<EmailRequestDTO[]>(this.getUrl(`GetPeriodEmailRequests/${id}`)).pipe(
      shareReplay()
    );
  }

  addUAPeriod(period: UAPeriodDTO): Observable<any> {
    return this.http.post<any>(this.getUrl('Add'), period).pipe(
      shareReplay()
    );
  }

  updateUAPeriod(period: UAPeriodDTO): Observable<any> {
    return this.http.post<any>(this.getUrl('Update'), period).pipe(
      shareReplay()
    );
  }

  calculateUAPeriodResults(id: number): Observable<any> {
    return this.http.get<any>(this.getUrl(`CalculateResults/${id}`)).pipe(
      shareReplay()
    );
  }

  getStudentLinks(periodId: number, schoolIds: number[], gradeIds: number[]): Observable<StudentLinkDTO[]> {
    const params = [
      ...schoolIds.map(id => `schoolIds=${id}`),
      ...gradeIds.map(id => `gradeIds=${id}`)
    ].join('&');
    return this.http.get<StudentLinkDTO[]>(this.getUrl(`GetStudentLinks/${periodId}?${params}`)).pipe(
      map(result => result),
      shareReplay()
    );
  }

  exportStudentLinksByPeriodId(periodId: number): Observable<StudentLinkDTO[]> {
    return this.http.get<StudentLinkDTO[]>(this.getUrl(`ExportStudentLinks/${periodId}`)).pipe(
      map(result => result),
      shareReplay()
    );
  }

  sendNotificationEmailsByPeriodId(periodId: number): Observable<any> {
    return this.http.get<any>(this.getUrl(`SendNotificationEmails/${periodId}`)).pipe(
      map(result => result),
      shareReplay()
    );
  }

  sendActivePeriodStudentEmails(periodId: number, districtWide: boolean, schoolIds: number[], gradeIds: number[]): Observable<any> {
    return this.http.post<any>(this.getUrl('SendActivePeriodStudentEmails'), { periodId, districtWide, schoolIds, gradeIds }).pipe(
      shareReplay()
    );
  }

  getSchoolPeriodByIdEdit(periodId: number, schoolId: number): Observable<SchoolPeriodEditDataDTO> {
    return this.http.get<SchoolPeriodEditDataDTO>(this.getUrl(`GetSchoolPeriodByIdEdit/${periodId}/${schoolId}`)).pipe(
      shareReplay()
    );
  }

  updateSchoolPeriod(period: UAPeriodSchoolEditDTO): Observable<any> {
    return this.http.post<any>(this.getUrl('UpdateSchoolPeriod'), period).pipe(
      shareReplay()
    );
  }
}
