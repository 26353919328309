import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgModel } from '@angular/forms';
import { SelectDTO } from '@app/Models/AppModels';
import * as Pipes from '@app/Pipes/index';

@Component({
    selector: 'app-single-select',
    templateUrl: './single-select.component.html',
    styleUrls: ['./single-select.component.css']
})
export class SingleSelectComponent implements OnInit {
    @Input() values: Array<SelectDTO>;
    @Input() selectedValue: number = null;
    @Input() disableAttr: boolean = false;
    @Input() isInvalid: boolean = false;
    @Input() labelText: String = "";
    @Input() isRequired: boolean = false;
    @Input() isSubmitted: boolean = false;

    searchText = "";

    @Output() setSelected = new EventEmitter<number>();

    constructor(private pipe: Pipes.SearchForSpecificPipe) { }

    ngOnInit() {
    }

    equals(objOne, objTwo) {
        if (typeof objOne !== 'undefined' && typeof objTwo !== 'undefined') {
            return objOne === objTwo;
        }
    }

    newOptionSelected() {
        this.setSelected.emit(this.selectedValue);
    }



}
