import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { 
  StudentMainDTO, StudentDetailsDTO, StudentEditDTO, StudentActiveDTO, StudentFileDTO, StudentCurrentEducation, StudentInfoDTO 
} from '@app/Models/student';
import { 
  StudentAssessmentGroupDTO, StudentImportRawData, RemainingTestsData, RemainingTestsGroupData 
} from '@app/Models/Assessment';
import { ExportStudentLinkDTO } from '@app/Models/Survey';

@Injectable({
  providedIn: 'root'
})
export class StudentsService {

  private studentAdded = '';
  private storedIds: number[] = [];

  private mtssValues = [
    { Id: '1', Value: 'Tier 1' },
    { Id: '2', Value: 'Tier 2' },
    { Id: '3', Value: 'Tier 3' }
  ];
  
  private disabilities = [
    { Id: 'Unspecified', Name: 'Unspecified' },
    { Id: 'asd', Name: 'Autism (ASD)' },
    { Id: 'deaf_blind', Name: 'Deaf-blindness' },
    { Id: 'deaf', Name: 'Deafness' },
    { Id: 'dd', Name: 'Developmental Delay (DD)' },
    { Id: 'ed', Name: 'Emotional Disturbance (ED)' },
    { Id: 'hi', Name: 'Hearing Impairment (HI)' },
    { Id: 'ind', Name: 'Intellectual Disability (IND)' },
    { Id: 'md', Name: 'Multiple Disabilities (MD)' },
    { Id: 'oi', Name: 'Orthopedic Impairment (OI)' },
    { Id: 'ohi', Name: 'Other Health Impairment (OHI)' },
    { Id: 'sld', Name: 'Specific Learning Disability (SLD)' },
    { Id: 'sli', Name: 'Speech or Language Impairment (SLI)' },
    { Id: 'tbi', Name: 'Traumatic Brain Injury (TBI)' },
    { Id: 'vi', Name: 'Visual Impairment (including blindness; VI)' }
  ];

  private mealStatus = [
    { Id: '0', Name: 'Unspecified' },
    { Id: '1', Name: 'None' },
    { Id: '2', Name: 'Free' },
    { Id: '3', Name: 'Reduced' }
  ];

  private interventionTypes = [
    { Id: '0', Name: 'Unspecified' },
    { Id: '1', Name: 'Vocabulary' },
    { Id: '2', Name: 'Spelling' },
    { Id: '3', Name: 'Language Mechanics' },
    { Id: '4', Name: 'Reading Comprehension' },
    { Id: '5', Name: 'Math computation' },
    { Id: '6', Name: 'Math Application' }
  ];

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  isStudentAdded(): boolean {
    return !!this.studentAdded;
  }

  getStudentAdded(): string {
    return this.studentAdded;
  }

  setStudentAdded(student: string): void {
    this.studentAdded = student;
  }

  clearStudentAdded(): void {
    this.studentAdded = '';
  }

  setRaterStudentIds(ids: number[]): void {
    this.storedIds = ids;
  }

  getRaterStudentIds(): number[] {
    return this.storedIds;
  }

  getMTSSValues(): { Id: string, Value: string }[] {
    return this.mtssValues;
  }

  getDisabilities(): { Id: string, Name: string }[] {
    return this.disabilities;
  }

  getMealStatus(): { Id: string, Name: string }[] {
    return this.mealStatus;
  }

  getInterventionTypes(): { Id: string, Name: string }[] {
    return this.interventionTypes;
  }

  importRawData(data: StudentImportRawData, periodId: number): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}api/Survey/ImportRawData/${periodId}`, data).pipe(
      shareReplay()
    );
  }

  sendStudentNotificationEmail(studentId: number, assessmentId: number): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}api/Survey/SendStudentNotificationEmail/${studentId}/${assessmentId}`, null).pipe(
      shareReplay()
    );
  }

  sendRemainingTests(data: RemainingTestsData): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}api/Survey/SendRemainingTests`, data).pipe(
      shareReplay()
    );
  }

  getAssessmentGroups(): Observable<StudentAssessmentGroupDTO[]> {
    return this.http.get<StudentAssessmentGroupDTO[]>(`${this.baseUrl}api/Survey/GetAssesmentGroups`).pipe(
      shareReplay()
    );
  }

  updateAssessmentGroupsRemainingTests(data: RemainingTestsGroupData): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}api/Survey/SendRemainingTestsGroup`, data).pipe(
      shareReplay()
    );
  }

  getStudentsMain(): Observable<StudentMainDTO[]> {
    return this.http.get<StudentMainDTO[]>(`${this.baseUrl}api/Students/GetAllStudentsMain`).pipe(
      shareReplay()
    );
  }

  getStudentDetailsById(id: number): Observable<StudentDetailsDTO> {
    return this.http.get<StudentDetailsDTO>(`${this.baseUrl}api/Students/GetStudentDetailsById/${id}`).pipe(
      shareReplay()
    );
  }

  getStudentByIdEdit(id: number): Observable<StudentEditDTO> {
    return this.http.get<StudentEditDTO>(`${this.baseUrl}api/Students/GetStudentByIdEdit/${id}`).pipe(
      shareReplay()
    );
  }

  getStudentsActiveState(): Observable<StudentActiveDTO[]> {
    return this.http.get<StudentActiveDTO[]>(`${this.baseUrl}api/Students/GetStudentsActiveState`).pipe(
      shareReplay()
    );
  }

  getStudentsInfo(): Observable<StudentInfoDTO[]> {
    return this.http.get<StudentInfoDTO[]>(`${this.baseUrl}api/Students/GetStudentsInfo`).pipe(
      shareReplay()
    );
  }

  importStudents(students: any[]): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}api/Students/Import`, students).pipe(
      shareReplay()
    );
  }

  addStudent(student: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}api/Students/Add`, student).pipe(
      shareReplay()
    );
  }

  updateStudent(student: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}api/Students/Update`, student).pipe(
      shareReplay()
    );
  }

  changeRater(data: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}api/Students/ChangeRater`, data).pipe(
      shareReplay()
    );
  }

  deactivateStudent(id: number): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}api/Students/Deactivate`, { id }).pipe(
      shareReplay()
    );
  }

  activateStudent(id: number): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}api/Students/Activate`, { id }).pipe(
      shareReplay()
    );
  }

  getStudentOtherBehavioralDataFile(studentId: number): Observable<StudentFileDTO[]> {
    return this.http.get<StudentFileDTO[]>(`${this.baseUrl}api/Files/GetFiles/${studentId}`).pipe(
      shareReplay()
    );
  }

  editStudentOtherBehavioralDataFile(fileId: number, title: string): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}api/Files/EditFile`, { Id: fileId, FileName: title }).pipe(
      shareReplay()
    );
  }

  removeOtherBehavioralDataFile(file: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}api/Files/DeleteFile`, file).pipe(
      shareReplay()
    );
  }

  getBlobOtherBehavioralDataFile(studentId: number, fileId: number): Observable<HttpEvent<Blob>> {
    return this.http.request<Blob>(new HttpRequest(
      'GET',
      `${this.baseUrl}api/Files/GetFile/${studentId}/${fileId}`,
      {
        reportProgress: true,
        responseType: 'blob'
      }
    ));
  }

  getCurrentEducationLevels(): Observable<StudentCurrentEducation[]> {
    return this.http.get<StudentCurrentEducation[]>(`${this.baseUrl}api/Students/GetCurrentEducationLevels`).pipe(
      shareReplay()
    );
  }
}
