import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../Services/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class SuperadminGuard implements CanActivate {
  constructor(private auth: AuthService, public router: Router) { }

  canActivate(next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    var path = state.url.split('/')[1]

    if (this.auth.isAdmin() && path == 'accounts') return true;
    else if (!this.auth.isAdmin() && (['school', 'staff', 'students', 'uaperiods', 'progress-monitoring', 'resources', 'user'].includes(path))) return true;

    this.router.navigate(['/login']);
    return false;
  }
}
