import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { ProgressMonitoringPlanTestDTO, PMAddEditDTO, ProgressMonitoringFlexItemAddEditDTO, ProgressMonitoringFlexItemDetailsDTO, ProgressMonitoringFlexItemMainDTO, ProgressMonitoringPlanDetailsDTO, ProgressMonitoringPlanFormData, ProgressMonitoringPlanMainDTO, ProgressMonitoringAssessmentMainDTO, ProgressMonitoringAssessmentFlexItemDTO, ProgressMonitoringPlanResultDTO, ProgressMonitoringPlanEmailDTO } from '@app/Models/ProgressMonitoring';
import { SurveyStudentAnswer } from '@app/Models/Survey';
import { UAPeriodMainDTO } from '@app/Models/UAPeriod';

@Injectable()
export class ProgressMonitoringService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  getAllPlansMain(): Observable<ProgressMonitoringPlanMainDTO[]> {
    return this.http.get<ProgressMonitoringPlanMainDTO[]>(`${this.baseUrl}api/ProgressMonitoring/GetAllPlansMain`).pipe(
      shareReplay()
    );
  }

  getPlanDetails(id: number): Observable<ProgressMonitoringPlanDetailsDTO> {
    return this.http.get<ProgressMonitoringPlanDetailsDTO>(`${this.baseUrl}api/ProgressMonitoring/GetPlanDetailsById/${id}`).pipe(
      shareReplay()
    );
  }

  getPlanEdit(id: number): Observable<PMAddEditDTO> {
    return this.http.get<PMAddEditDTO>(`${this.baseUrl}api/ProgressMonitoring/GetPlanByIdEdit/${id}`).pipe(
      shareReplay()
    );
  }

  getPMFormData(): Observable<ProgressMonitoringPlanFormData> {
    return this.http.get<ProgressMonitoringPlanFormData>(`${this.baseUrl}api/ProgressMonitoring/GetPMFormData`).pipe(
      shareReplay()
    );
  }

  addPlan(plan: PMAddEditDTO): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}api/ProgressMonitoring/AddPlan`, plan).pipe(
      shareReplay()
    );
  }

  updatePlan(plan: PMAddEditDTO): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}api/ProgressMonitoring/UpdatePlan`, plan).pipe(
      shareReplay()
    );
  }

  addPlanDate(planId: number, studentId: number): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}api/ProgressMonitoring/AddPlanDate/${planId}/${studentId}`, null).pipe(
      shareReplay()
    );
  }

  getAllFlexItemsMain(): Observable<ProgressMonitoringFlexItemMainDTO[]> {
    return this.http.get<ProgressMonitoringFlexItemMainDTO[]>(`${this.baseUrl}api/ProgressMonitoring/GetAllFlexItemsMain`).pipe(
      shareReplay()
    );
  }

  getFlexItemDetails(itemId: number): Observable<ProgressMonitoringFlexItemDetailsDTO> {
    return this.http.get<ProgressMonitoringFlexItemDetailsDTO>(`${this.baseUrl}api/ProgressMonitoring/GetFlexItemDetails/${itemId}`).pipe(
      shareReplay()
    );
  }

  getFlexItemByIdEdit(itemId: number): Observable<ProgressMonitoringFlexItemAddEditDTO> {
    return this.http.get<ProgressMonitoringFlexItemAddEditDTO>(`${this.baseUrl}api/ProgressMonitoring/GetFlexItemByIdEdit/${itemId}`).pipe(
      shareReplay()
    );
  }

  addFlexItem(plan: ProgressMonitoringFlexItemAddEditDTO): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}api/ProgressMonitoring/AddFlexItem`, plan).pipe(
      shareReplay()
    );
  }

  updateFlexItem(plan: ProgressMonitoringFlexItemAddEditDTO): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}api/ProgressMonitoring/UpdateFlexItem`, plan).pipe(
      shareReplay()
    );
  }

  deleteFlexItem(itemId: number): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}api/ProgressMonitoring/DeleteFlexItem/${itemId}`).pipe(
      shareReplay()
    );
  }

  getPMTestMainData(planId: number, token: string): Observable<ProgressMonitoringAssessmentMainDTO> {
    return this.http.get<ProgressMonitoringAssessmentMainDTO>(`${this.baseUrl}api/ProgressMonitoring/GetPMTestMainData/${planId}/${token}`).pipe(
      shareReplay()
    );
  }

  getPMTestQuestions(planId: number, token: string, itemId: number): Observable<ProgressMonitoringAssessmentFlexItemDTO> {
    return this.http.get<ProgressMonitoringAssessmentFlexItemDTO>(`${this.baseUrl}api/ProgressMonitoring/GetPMTestQuestions/${planId}/${token}/${itemId}`).pipe(
      shareReplay()
    );
  }

  submitPMTestAnswers(planId: number, token: string, itemId: number, answers: SurveyStudentAnswer[]): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}api/ProgressMonitoring/SubmitPMTestAnswers/${planId}/${token}/${itemId}`, answers).pipe(
      shareReplay()
    );
  }

  getPlanStudentResults(planId: number, studentId: number): Observable<ProgressMonitoringPlanResultDTO> {
    return this.http.get<ProgressMonitoringPlanResultDTO>(`${this.baseUrl}api/ProgressMonitoring/GetPlanStudentResults/${planId}/${studentId}`).pipe(
      shareReplay()
    );
  }

  getPlanStudentResultsDetailedInfo(planDateId: number, studentId: number): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}api/ProgressMonitoring/GetResultsDetailed/${planDateId}/${studentId}`).pipe(
      shareReplay()
    );
  }

  sendAllStudentsNotificationEmail(data: ProgressMonitoringPlanEmailDTO): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}api/ProgressMonitoring/SendAllStudentsNotificationEmail`, data).pipe(
      shareReplay()
    );
  }

  sendStudentNotificationEmail(data: ProgressMonitoringPlanEmailDTO): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}api/ProgressMonitoring/SendStudentNotificationEmail`, data).pipe(
      shareReplay()
    );
  }

  getPlanTestStudentAnswers(planDateId: number, studentId: number): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}api/ProgressMonitoring/GetPlanTestStudentAnswers/${planDateId}/${studentId}`).pipe(
      shareReplay()
    );
  }

  addEditStudentAnswers(planDateId: number, studentId: number, items: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}api/ProgressMonitoring/AddEditStudentAnswers/${planDateId}/${studentId}`, items).pipe(
      shareReplay()
    );
  }
}
