import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatInputModule } from '@angular/material/input';
import { MatSliderModule } from '@angular/material/slider';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core'; // Note: MatOptionModule is under core
import { MatTabsModule } from '@angular/material/tabs';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker'
import {MatStepperModule} from '@angular/material/stepper'

import { NgxPaginationModule } from 'ngx-pagination';
import { MultipleSelectComponent } from '@app/CustomComponents/multiple-select/multiple-select.component';

import * as Pipes from '@app/Pipes/index';
import { DetailsStaffComponent } from './staff/details-staff/details-staff.component';
import { ActivateMemberStaffComponent } from './staff/activate-member-staff/activate-member-staff.component';
import { DeactivateMemberStaffComponent } from './staff/deactivate-member-staff/deactivate-member-staff.component';
import { LoginLinkStaffComponent } from './staff/login-link-staff/login-link-staff.component';
import { ChangePasswordComponent } from './staff/change-password/change-password.component';

import { RouterModule, Routes } from '@angular/router';
import { RequestLicensesModal } from './staff/request-licenses/request-licenses-modal';
import { SingleSelectComponent } from '@app/CustomComponents/single-select/single-select.component';

@NgModule({
  declarations: [
    MultipleSelectComponent,
    SingleSelectComponent,

    Pipes.OrderByPipe,
    Pipes.SearchForPipe,
    Pipes.SplitByPipe,
    Pipes.SearchForSpecificPipe,
    Pipes.SearchColumnsPipe,

    DetailsStaffComponent,
    LoginLinkStaffComponent,
    DeactivateMemberStaffComponent,
    ActivateMemberStaffComponent,
    ChangePasswordComponent,
    RequestLicensesModal
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,

    MatInputModule,
    MatButtonModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    MatCardModule,
    MatDialogModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatSelectModule,
    MatTooltipModule,
    MatOptionModule,
    MatTabsModule,
    MatSnackBarModule,
    MatListModule,
    MatStepperModule,
    MatInputModule,
    MatSliderModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    MatInputModule,
    MatButtonModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    MatCardModule,
    MatDialogModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatSelectModule,
    MatTooltipModule,
    MatOptionModule,
    MatTabsModule,
    MatSnackBarModule,
    MatListModule,
    MatStepperModule,
    MatInputModule,
    MatSliderModule,
    NgxPaginationModule,

    Pipes.OrderByPipe,
    Pipes.SearchForPipe,
    Pipes.SplitByPipe,
    Pipes.SearchForSpecificPipe,
    Pipes.SearchColumnsPipe,

    MultipleSelectComponent,
    SingleSelectComponent
  ],
  providers: [
    Pipes.SearchForSpecificPipe
  ]
})
export class SharedModule { }
