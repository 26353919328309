// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title {
    width: 100%;
    border-bottom: 3px solid rgba(2, 15, 152, 0.83);
    text-transform: uppercase;
    text-align: center;
}

.title h2 {
    margin-bottom: 0;
    font-weight: bold;
}

mat-dialog-content {
    margin-top: 20px;
    text-align: center;
}

.line {
    float: left;
    width: 100%;
    margin: 5px 0;
    text-align: left;
}

button {
    margin: 5px 5px 20px;
}

#licensesTable {
    margin-left: 20px;
}

#licensesTable tr>*:nth-child(1) {
    text-align: left;
    padding-left: 20px;
}

#licensesTable tr>*:nth-child(2) {
    width: 100px;
    min-width: initial;
}

#licensesTable input {
    width: 100px;
    min-width: initial;
    margin: 0 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/SharedModules/shared/staff/request-licenses/request-licenses-modal.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,+CAA+C;IAC/C,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,WAAW;IACX,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,cAAc;AAClB","sourcesContent":[".title {\r\n    width: 100%;\r\n    border-bottom: 3px solid rgba(2, 15, 152, 0.83);\r\n    text-transform: uppercase;\r\n    text-align: center;\r\n}\r\n\r\n.title h2 {\r\n    margin-bottom: 0;\r\n    font-weight: bold;\r\n}\r\n\r\nmat-dialog-content {\r\n    margin-top: 20px;\r\n    text-align: center;\r\n}\r\n\r\n.line {\r\n    float: left;\r\n    width: 100%;\r\n    margin: 5px 0;\r\n    text-align: left;\r\n}\r\n\r\nbutton {\r\n    margin: 5px 5px 20px;\r\n}\r\n\r\n#licensesTable {\r\n    margin-left: 20px;\r\n}\r\n\r\n#licensesTable tr>*:nth-child(1) {\r\n    text-align: left;\r\n    padding-left: 20px;\r\n}\r\n\r\n#licensesTable tr>*:nth-child(2) {\r\n    width: 100px;\r\n    min-width: initial;\r\n}\r\n\r\n#licensesTable input {\r\n    width: 100px;\r\n    min-width: initial;\r\n    margin: 0 10px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
