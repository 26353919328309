import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'searchColumns',
    pure: false
})
export class SearchColumnsPipe implements PipeTransform {

    result = [];

    transform(values: any, args?: Object): any {
        if (this.checkProperties(args) || values.length == 0) {
            return values;
        }
        this.result = values;
        this.ObjectReader(args, []);
        if (this.result.length == 0) {
            return [false];
        }
        return this.result;
    }

    checkProperties(obj) {
        for (var key in obj) {
            if (obj[key] !== null && obj[key] != "")
                return false;
        }
        return true;
    }

    //osa parapano inner objects exoume, tosa parapano epipeda einai to dentro
    //oso petuxainei string menei sto idio epipedo tou dentrou, allios katevainei epipedo
    //einai Preorder Tree Traversal, an einai komvos-node, simainei einai object
    //http://1.bp.blogspot.com/-i5V7Jmthi7M/VDUiXMxqEBI/AAAAAAAADjo/ugl4rprT5PQ/s1600/Figure1010.gif
    //http://docplayer.net/docs-images/70/63896148/images/10-0.jpg
    ObjectReader(nodeObject, oldKeys) {
        var arg;
        Object.keys(nodeObject).forEach(key => {
            //  console.log(nodeObject)
            if (nodeObject[key]) {
                if (typeof nodeObject[key] === 'string') {
                    arg = nodeObject[key].toString().toLowerCase();
                    this.result = this.result.filter(obj => {
                        var oldObj = obj;
                        oldKeys.forEach(oldKey => { oldObj = oldObj[oldKey]; }) //krataei ta prohgoumena keys, oso pio kato epipedo, tosa parapano keys
                        if (!oldObj) return false;
                        else if (oldObj[key]) return oldObj[key].toString().toLowerCase().indexOf(arg) !== -1; //an do8oun la8os key stin klisi na gurnaei esto tin arxiki lista, anti gia error
                        else return this.result;
                    })
                }
                else if (typeof nodeObject[key] === 'object') {
                    oldKeys.push(key);
                    this.ObjectReader(nodeObject[key], oldKeys);
                }
            }
        });
    }
}

