import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService, UsersService } from '../Services';
import { Router } from '@angular/router';
import { SetupInfo } from '@app/Models/User';
import { Location } from '@angular/common';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private loc: Location, private authService: AuthService, private usersService: UsersService, public router: Router) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        if (!this.authService.isLoggedIn()) { //if there is no token
            this.router.navigate(['/login']);
            return false;
        }
        else if (this.authService.isTokenExpired()) { //if there is a token but it is expired
            this.authService.logout();
            return false;
        }

        if (this.authService.isLoggedIn()) {

            if (this.authService.isSetupRequired()) {
                this.usersService.getLoggedInSetupInfo().subscribe(
                    (result: SetupInfo) => {
                        this.router.navigate(['/setup/' + result.Id + '/' + result.Token]);
                    },
                    error => {
                        this.router.navigate(['/login']);
                    }
                )
            }
            else if (!this.authService.hasAgreedOnTerms()) {
                this.router.navigate(['/terms']);
            }
            else if (!this.authService.isActive()) {
                this.authService.logout();
            }

        }

        return true;
    }

}
