import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { UserEmail, SetupInfo } from '@app/Models/User';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private apiUrl = 'api/Users/';

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  private getUrl(endpoint: string): string {
    return `${this.baseUrl}${this.apiUrl}${endpoint}`;
  }

  requestResetPassword(data: any): Observable<string> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<string>(this.getUrl('RequestResetPassword'), data, { headers, responseType: 'text' as 'json' }).pipe(
      shareReplay()
    );
  }

  setupPassword(data: any): Observable<string> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<string>(this.getUrl('Setup'), data, { headers, responseType: 'text' as 'json' }).pipe(
      shareReplay()
    );
  }

  getSetupInfo(id: number, token: string): Observable<UserEmail> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get<UserEmail>(this.getUrl(`GetSetupInfo/${id}/${token}`), { headers }).pipe(
      shareReplay()
    );
  }

  getLoggedInSetupInfo(): Observable<SetupInfo> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get<SetupInfo>(this.getUrl('GetLoggedInSetupInfo'), { headers }).pipe(
      shareReplay()
    );
  }

  changePassword(data: any): Observable<string> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<string>(this.getUrl('ChangePassword'), data, { headers, responseType: 'text' as 'json' }).pipe(
      shareReplay()
    );
  }

  setAgreedOnTerms(): Observable<string> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<string>(this.getUrl('SetAgreedOnTerms'), {}, { headers, responseType: 'text' as 'json' }).pipe(
      shareReplay()
    );
  }

  getAccountName(): Observable<string> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get<string>(this.getUrl('GetAccountName'), { headers, responseType: 'text' as 'json' }).pipe(
      shareReplay()
    );
  }

  getHelpFile(fileId: number) {
    return this.http.request(new HttpRequest('GET', this.baseUrl + `api/Files/GetHelpFile/${fileId}`, null, { reportProgress: true, responseType: 'blob' })).toPromise();
}
}


