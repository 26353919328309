import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UsersService, AuthService } from '@app/Services';
import { UserEmail } from '@app/Models/User';
import { FormGroup, NgModel } from '@angular/forms';

@Component({
    selector: 'app-setup-account',
    templateUrl: './setup-account.component.html',
    styleUrls: ['./setup-account.component.css']
})
export class SetupAccountComponent implements OnInit {
    @ViewChild('password') passwordModel: NgModel;
    @ViewChild('password2') password2Model: NgModel;

    model: any = { 'Email': '', 'Password': '', 'Password2': '', 'Token': '' };

    emailDisabled = true;
    passwordVisible = false;
    password2Visible = false;

    isPasswordValid = false;

    allowSetup = true;

    constructor(private route: ActivatedRoute, private router: Router, private usersService: UsersService, private authService: AuthService) {
        var id = this.route.snapshot.paramMap.get('id');
        var token = this.route.snapshot.paramMap.get('token');
        if (token) this.model.Token = token;
        if (id && token) {
            this.usersService.getSetupInfo(+id, token).subscribe(
                (result: UserEmail) => {
                    this.model.Email = result.Email;
                },
                errors => {
                    this.allowSetup = false;
                }
            );
        }
    }

    ngOnInit() {
    }

    testPasswordValidity() {
        var regex = RegExp(`^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()?,.;<>':_-]).{8,}$`);
        this.isPasswordValid = regex.test(this.model.Password);
        if (!regex.test(this.model.Password)) setTimeout(() => this.passwordModel.control.setErrors({ 'nomatch': true }));
        else this.passwordModel.control.setErrors(null);
    }

    testConfirmationPassword() {
        if (this.model.Password != this.model.Password2) setTimeout(() => this.password2Model.control.setErrors({ 'nomatch': true }));
        else this.password2Model.control.setErrors(null);
    }

    submit() {
        if (this.model.Password && this.model.Password2 && this.model.Password == this.model.Password2 && this.isPasswordValid) {
            this.usersService.setupPassword(this.model).subscribe(
                result => {
                    this.authService.alertSuccess("Your account was successfully setup.")
                    this.authService.logout();
                },
                errors => { this.authService.alertError('An error occured while setting up your password.') }
            );
        }
        //alert(JSON.stringify(this.model))
    }

    tryLogout() {
        if (this.authService.isLoggedIn()) this.authService.logout();
    }
}
