import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgModel } from '@angular/forms';
import { SelectDTO } from '@app/Models/AppModels';
import * as Pipes from '@app/Pipes/index';

@Component({
    selector: 'app-multiple-select',
    templateUrl: './multiple-select.component.html',
    styleUrls: ['./multiple-select.component.css']
})
export class MultipleSelectComponent implements OnInit {
    @Input() values: Array<SelectDTO>;
    @Input() selectedValues: Array<number> = [];
    @Input() initSelectAll: boolean = false;
    @Input() allText: String;
    @Input() disableAttr: boolean = false;
    @Input() isInvalid: boolean = false;
    @Input() labelText: String = "";
    @Input() isRequired: boolean = false;
    @Input() isSubmitted: boolean = false;

    hasSelectedAll = true;
    once = true;
    searchText = "";

    allSelections = [];

    @Output() setSelected = new EventEmitter<Array<number>>();

    constructor(private pipe: Pipes.SearchForSpecificPipe) { }

    ngOnInit() {
    }

    ngOnChanges(changes) {
        if (this.initSelectAll && this.once) {
            this.selectedValues = this.values.map(x => x.Id);
            this.hasSelectedAll = false;
            this.newOptionSelected();
        }
    }

    equals(objOne, objTwo) {
        if (typeof objOne !== 'undefined' && typeof objTwo !== 'undefined') {
            return objOne === objTwo;
        }
    }

    selectAll(select: NgModel) {
        if (this.hasSelectedAll) select.update.emit(this.pipe.transform(this.values, 'Text', this.searchText).map(x => x.Id));
        else select.update.emit([]);
        this.hasSelectedAll = !this.hasSelectedAll;
        this.newOptionSelected();
    }

    newOptionSelected() {
        var shownList = this.pipe.transform(this.values, 'Text', this.searchText).map(x => x.Id);
        shownList.forEach(item => {
            var index = this.allSelections.indexOf(item);
            if (index >= 0) this.allSelections.splice(index, 1);
        })
        this.allSelections = [...this.allSelections, ...this.selectedValues]

        this.setSelected.emit(this.allSelections);
    }

    showAllSelected() {
        this.selectedValues = this.allSelections;
    }



}
