import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { map, shareReplay } from 'rxjs/operators';

import { AccountMainDTO, AccountEditDTO, AccountDetailsDTO, AccountToken, AccountNewLicensesDTO, AccountLicensesDTO } from '@app/Models/Account';
import { StudentDataExportDTO } from '@app/Models/student';

@Injectable()
export class AccountsService {
  private accAdded = '';

  constructor(private http: HttpClient, public router: Router, @Inject('BASE_URL') private baseUrl: string) { }

  // Getter and Setter methods for accAdded
  isAccAdded() {
    return !!this.accAdded;
  }

  getAccAdded() {
    return this.accAdded;
  }

  setAccAdded(acc: string) {
    this.accAdded = acc;
  }

  clearAccAdded() {
    this.accAdded = '';
  }

  // API Methods
  getAccountsMain() {
    return this.http.get<Array<AccountMainDTO>>(`${this.baseUrl}api/Accounts/GetAllAccountsMain`)
      .pipe(
        map(result => result),
        shareReplay()
      );
  }

  getAccountByIdEdit(id: number) {
    return this.http.get<AccountEditDTO>(`${this.baseUrl}api/Accounts/GetAccountByIdEdit/${id}`)
      .pipe(
        map(result => result),
        shareReplay()
      );
  }

  getAccountDetailsById(id: number) {
    return this.http.get<AccountDetailsDTO>(`${this.baseUrl}api/Accounts/GetAccountDetailsById/${id}`)
      .pipe(
        map(result => result),
        shareReplay()
      );
  }

  exportAccountData(accountId: number) {
    return this.http.get<StudentDataExportDTO[]>(`${this.baseUrl}api/Accounts/ExportStudentsData/${accountId}`)
      .pipe(
        map(result => result),
        shareReplay()
      );
  }

  addAccount(account: any) {
    return this.http.post(`${this.baseUrl}api/Accounts/Add`, account)
      .pipe(shareReplay());
  }

  updateAccount(account: any) {
    return this.http.post(`${this.baseUrl}api/Accounts/Update`, account)
      .pipe(shareReplay());
  }

  resendSetupAccountEmail(accountId: number) {
    return this.http.post(`${this.baseUrl}api/Accounts/ResendSetupAccountEmail`, accountId)
      .pipe(shareReplay());
  }

  getAccountLicenses(id: number) {
    return this.http.get<AccountLicensesDTO>(`${this.baseUrl}api/Accounts/GetAccountLicenses/${id}`)
      .pipe(
        map(result => result),
        shareReplay()
      );
  }

  addAccountLicenses(newLicenses: AccountNewLicensesDTO) {
    return this.http.post<AccountDetailsDTO>(`${this.baseUrl}api/Accounts/AddAccountLicenses`, newLicenses)
      .pipe(shareReplay());
  }

  loginAsAccountAdmin(id: number) {
    return this.http.post<AccountToken>(`${this.baseUrl}api/Accounts/LoginToAccount`, id)
      .pipe(shareReplay());
  }

  deactivate(accountId: number) {
    return this.http.post(`${this.baseUrl}api/Accounts/Deactivate`, accountId)
      .pipe(shareReplay());
  }

  activate(accountId: number) {
    return this.http.post(`${this.baseUrl}api/Accounts/Activate`, accountId)
      .pipe(shareReplay());
  }

  delete(accountId: number) {
    return this.http.post(`${this.baseUrl}api/Accounts/Delete`, accountId)
      .pipe(shareReplay());
  }
}
