import { NgModule } from '@angular/core';
import { Route, RouterModule, Routes } from '@angular/router';

import * as UserComponents from './UserComponents/index';

import { AuthGuard } from './Guards/auth.guard';
import { SuperadminGuard } from './Guards/superadmin.guard';

import { TestNavComponent } from './UserComponents/test-nav/test-nav.component';
import { DetailsStaffComponent } from './SharedModules/shared/staff/details-staff/details-staff.component';

import { SelectivePreloadingStrategy } from './app-selective-preloading';

const routes: Routes = [
    { path: '', redirectTo: '/login', pathMatch: 'full' },
    { path: 'login', component: UserComponents.LoginComponent },
    { path: 'setup/:id/:token', component: UserComponents.SetupAccountComponent },
    { path: 'reset', component: UserComponents.ResetComponent },
    { path: 'terms', component: UserComponents.TermsComponent },
    { path: 'resetPassword/:id/:token', component: UserComponents.SetupAccountComponent },
    {
        path: '',
        component: TestNavComponent,
        // component: UserComponents.NavigationBarComponent,
        canActivate: [AuthGuard, SuperadminGuard],
        children: [
            {
                path: 'school',
                loadChildren: () =>  import('./FeatureModules/admin/school/school.module').then(m => m.SchoolModule),
                data: { preload: true, myvar: "appUser" }
            },
            {
                path: 'staff',
                loadChildren: () =>  import('./FeatureModules/admin/staff/staff.module').then(m => m.StaffModule),
                data: { preload: true, myvar: "appUser" }
            },
            {
                path: 'students',
                loadChildren: () =>  import('./FeatureModules/admin/student/student.module').then(m => m.StudentModule),
                data: { preload: true, myvar: "appUser" }
            },
            {
                path: 'uaperiods',
                loadChildren: () =>  import('./FeatureModules/admin/ua-periods/ua-periods.module').then(m => m.UaPeriodsModule),
                data: { preload: true, myvar: "appUser" }
            },
            {
                path: 'progress-monitoring',
                loadChildren: () =>  import('./FeatureModules/admin/progress-monitoring/progress-monitoring.module').then(m => m.ProgressMonitoringModule),
                data: { preload: true, myvar: "appUser" }
            },
            {
                path: 'resources',
                loadChildren: () =>  import('./FeatureModules/admin/resources/resources.module').then(m => m.ResourcesModule),
                data: { preload: true, myvar: "appUser" }
            },
            {
                path: 'accounts',
                canActivate: [SuperadminGuard],
                loadChildren: () =>  import('./FeatureModules/account/account.module').then(m => m.AccountModule),
                data: { preload: true, myvar: "appAdmin" }
            },
            {
                path: 'user',
                canActivate: [SuperadminGuard],
                children: [
                    {
                        path: 'myAccount',
                        component: DetailsStaffComponent,
                        canActivate: [AuthGuard]
                    }
                ]
            }
        ]
    },
    {
        path: '',
        loadChildren: () =>  import('./FeatureModules/assessment/assessment.module').then(m => m.AssessmentModule),
        data: { preload: true, myvar: "assessmentUser" }
    },
    {
        path: 'progress-monitoring/:planId/:token',
        loadChildren: () => import('./FeatureModules/assessment/assessment.module').then(m => m.AssessmentModule),
        data: { preload: true, myvar: "assessmentUser" }
    },
    { path: '**', redirectTo: '/login' }
];


@NgModule({
    imports: [
        RouterModule.forRoot(
            routes,
            {
                preloadingStrategy: SelectivePreloadingStrategy
            }
        )
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
    matchInts() {

    }
}