import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

interface User {
    Email: string;
    Password: string;
}

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private jwtHelper = new JwtHelperService();

    constructor(
        private snackBar: MatSnackBar,
        private http: HttpClient,
        public router: Router,
        @Inject('BASE_URL') private baseUrl: string
    ) { }

    login(user: User): Observable<void> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post<{ Token: string }>(`${this.baseUrl}api/Users/Login`, user, { headers, responseType: 'json' })
            .pipe(
                map(response => {
                    const token = response.Token;
                    localStorage.setItem('token', token);
                })
            );
    }

    logout(): void {
        localStorage.removeItem('token');
        this.router.navigate(['/login']);
    }

    performAccountAdminLogin(token: string): void {
        localStorage.removeItem('token');
        localStorage.setItem('token', token);
        setTimeout(() => this.router.navigate(['/schools']), 1000);
    }

    decodeToken(): any {
        const token = localStorage.getItem('token');
        return token ? this.jwtHelper.decodeToken(token) : null;
    }

    getRole(): string | null {
        const user = this.decodeToken();
        return user ? user['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] : null;
    }

    isAdmin(): boolean {
        const user = this.decodeToken();
        return !!user && +user['IsAdmin'] === 1;
    }

    isSetupRequired(): boolean {
        const user = this.decodeToken();
        return !!user && +user['SetupRequired'] === 1;
    }

    hasAgreedOnTerms(): boolean {
        const user = this.decodeToken();
        return !!user && +user['AgreedOnTerms'] === 1;
    }

    isActive(): boolean {
        const user = this.decodeToken();
        return !!user && +user['Active'] === 1;
    }

    hasMath(): boolean {
        const user = this.decodeToken();
        return !!user && +user['HasMath'] === 1;
    }

    hasVerbal(): boolean {
        const user = this.decodeToken();
        return !!user && +user['HasVerbal'] === 1;
    }

    hasComprehensive(): boolean {
        const user = this.decodeToken();
        return !!user && +user['HasComprehensive'] === 1;
    }

    getUser(): any {
        return this.decodeToken();
    }

    isLoggedIn(): boolean {
        return !!localStorage.getItem('token');
    }

    isLoggedOut(): boolean {
        return !this.isLoggedIn();
    }

    isTokenExpired(): boolean {
        const token = localStorage.getItem('token');
        return token ? this.jwtHelper.isTokenExpired(token) : true;
    }

    alertError(text: string): void {
        this.snackBar.open(text, 'Χ', {
            duration: 10000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: ['mt-3', 'rounded', 'alert-danger']
        });
    }

    alertSuccess(text: string): void {
        this.snackBar.open(text, 'Χ', {
            duration: 10000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: ['mt-3', 'rounded', 'alert-success', 'snackBar-changeLine']
        });
    }

    alertWarning(text: string): void {
        this.snackBar.open(text, 'Χ', {
            duration: 10000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: ['mt-3', 'rounded', 'alert-warning']
        });
    }
}
