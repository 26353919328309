import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
    @ViewChild('newPasswordInput') passwordModel: NgModel;
    @ViewChild('newPassword2Input') password2Model: NgModel;

    oldPassword = "";
    newPassword = "";
    newPassword2 = "";
    oldPasswordVisible = false;
    newPasswordVisible = false;
    newPassword2Visible = false;

    isPasswordValid = false;

    passwordCriteria = "First line&#13;Second line";

    constructor(public thisDialogRef: MatDialogRef<ChangePasswordComponent>) { }

    ngOnInit() {
    }

    testPasswordValidity() {
        var regex = RegExp(`^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()?,.;<>':_-]).{8,}$`);
        this.isPasswordValid = regex.test(this.newPassword);
        if (!regex.test(this.newPassword)) setTimeout(() => this.passwordModel.control.setErrors({ 'nomatch': true }));
        else this.passwordModel.control.setErrors(null);
    }

    testConfirmationPassword() {
        if (this.newPassword != this.newPassword2) setTimeout(() => this.password2Model.control.setErrors({ 'nomatch': true }));
        else this.password2Model.control.setErrors(null);
    }

    onCloseConfirm() {
        if (this.isPasswordValid) this.thisDialogRef.close({ 'OldPassword': this.oldPassword, 'NewPassword': this.newPassword, 'NewPassword2': this.newPassword2 });
    }

    onCloseCancel() {
        this.thisDialogRef.close(false);
    }
}
