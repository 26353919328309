// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title {
    width: 100%;
    border-bottom: 3px solid rgba(2, 15, 152, 0.83);
    text-transform: uppercase;
    text-align: center;
}

.title h2 {
    margin-bottom: 0;
    font-weight: bold;
}

mat-dialog-content {
    margin-top: 20px;
    text-align: center;
}

input[type="password"] {
    width: 100%;
}

mat-form-field {
    width: 70%;
    min-width: max-content;
}

::ng-deep .my-tooltip {
    white-space: pre-line !important;
}

.alert {
    margin-top: 10px;
    clear: both;
}

button {
    margin: 5px 5px 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/SharedModules/shared/staff/change-password/change-password.component.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,+CAA+C;IAC/C,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,UAAU;IACV,sBAAsB;AAC1B;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,oBAAoB;AACxB","sourcesContent":[".title {\r\n    width: 100%;\r\n    border-bottom: 3px solid rgba(2, 15, 152, 0.83);\r\n    text-transform: uppercase;\r\n    text-align: center;\r\n}\r\n\r\n.title h2 {\r\n    margin-bottom: 0;\r\n    font-weight: bold;\r\n}\r\n\r\nmat-dialog-content {\r\n    margin-top: 20px;\r\n    text-align: center;\r\n}\r\n\r\ninput[type=\"password\"] {\r\n    width: 100%;\r\n}\r\n\r\nmat-form-field {\r\n    width: 70%;\r\n    min-width: max-content;\r\n}\r\n\r\n::ng-deep .my-tooltip {\r\n    white-space: pre-line !important;\r\n}\r\n\r\n.alert {\r\n    margin-top: 10px;\r\n    clear: both;\r\n}\r\n\r\nbutton {\r\n    margin: 5px 5px 20px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
