import { UAPeriodScoringDTO } from "./UAPeriod";

export interface VerbalQuestionGroup {
    GroupType: number;
    GroupDescription: string;
    GroupPassage: string;
    Questions: VerbalQuestion[];
}
export interface VerbalQuestionGroupTest {
    GroupType: number;
    GroupDescription: string;
    GroupPassage: string;
    Questions: VerbalQuestionTest[];
}
export interface VerbalQuestion {
    QuestionId: string;
    QuestionText: string;
    CorrectAnswer: string;
    Answers: SurveyAnswer[];
    SelectedAnswer: string;
}
export class VerbalQuestionTest {
    QuestionId: string;
    QuestionText: string;
    CorrectAnswer: string;
    Answers: SurveyAnswer[];
    SelectedAnswer: string;

    constructor(questionId, questionText, correctAnswer, answers) {
        this.QuestionId = questionId;
        this.QuestionText = questionText;
        this.CorrectAnswer = correctAnswer;
        this.SelectedAnswer = null;
        this.Answers = answers;
    }
}
export class SurveyAnswer {
    AnswerId: string;
    Answer: string;
    AnswerImage: number[];

    constructor(answerId, answer) {
        this.AnswerId = answerId;
        this.Answer = answer;
        this.AnswerImage = [];
    }
}

export class SurveyStudentAnswer {
    QuestionId: string;
    AnswerId: string;

    constructor(question, answer) {
        this.QuestionId = question;
        this.AnswerId = answer;
    }
}

export interface MathQuestionTest {
    QuestionId: string;
    QuestionText: string;
    CorrectAnswer: string;
    QuestionImage: string | ArrayBuffer;
    Submitted: boolean;
    Answers: SurveyAnswer[];
    SelectedAnswer: string;
}

export interface MathQuestion {
    QuestionId: string;
    QuestionText: string;
    CorrectAnswer: string;
    QuestionImage: number[];
    HasImageAnswers: boolean;
    Answers: SurveyAnswer[];
    SelectedAnswer: string;
}

export interface SurveyData {
    StudentFullName: string;
    Timed: boolean;
    Level: string;
    Form: string;
    AllowMath: boolean;
    AllowVerbal: boolean;
    AllowComprehensive: boolean;
    IsMathAssessed: boolean;
    IsVerbalAssessed: boolean;

    TakeVocabulary: boolean;
    TakeSpelling: boolean;
    TakeLanguageMechanics: boolean;
    TakeReadingComprehension: boolean;
    TakeMathComputation: boolean;
    TakeMathApplication: boolean;

    IsVocabularyAssessed: boolean;
    IsSpellingAssessed: boolean;
    IsLanguageMechanicsAssessed: boolean;
    IsReadingComprehensionAssessed: boolean;
    IsMathComputationAssessed: boolean;
    IsMathApplicationAssessed: boolean;
}

export interface StudentAuthorization {
    AssessmentId: number;
    Token: string;
}

export interface SurveyQuestionAnswer {
    QuestionId: string;
    AnswerId: string;
}

export interface ScoringData {
    Period: UAPeriodScoringDTO;
    StudentName: string;
    StudentGradeId: number;
    VerbalQuestions: SurveyQuestionAnswer[];
    MathQuestions: SurveyQuestionAnswer[];
    ComprQuestionsCount: ComprQuestionsCount[];
}

export interface ImportRawDataCountersDTO {
    Form: string;
    Timed: boolean;
    StudentIds: string[];
    VerbalQuestionsCount: number;
    MathQuestionsCount: number;
    ComprQuestionsCount: ComprQuestionsCount[];
}

export interface ComprQuestionsCount {
    Level: string;
    Form: string;
    CategoryId: number;
    Counter: number;
}


export interface PDFData {
    Timed: boolean,
    ELL: string,
    Accommodations: boolean,

    Student: PDFStudent;
    MathTable: PDFMathTableData;
    VerbalTable: PDFVerbalTableData;
    MathComputationQuestions: PDFQuestion[];
    MathApplicationQuestions: PDFQuestion[];
    VocabularyQuestions: PDFQuestion[];
    LanguageMechanicsQuestions: PDFQuestion[];
    ReadingComprehensionQuestions: PDFQuestion[];
}

export interface PDFQuestion {
    Id: number;
    Description: string;
    Response: string;
}

export interface PDFStudent {
    Name: string;
    Surname: string;
    StudentId: string;
    Gender: string;
    BirthDate: string;
    Age: string;
    YearsOfEducation: string;
    TestDate: string;
    ReportDate: string;
}

export class PDFMathTableData {
    StandardScore: number;
    LowEnd: number;
    HighEnd: number;
    Percentile: number;
    GradeEquivalent: number;
    AgeEquivalent: number;
    ComputationSS: number;
    ApplicationSS: number;
}

export class PDFVerbalTableData {
    StandardScore: number;
    LowEnd: number;
    HighEnd: number;
    Percentile: number;
    GradeEquivalent: number;
    AgeEquivalent: number;
    VocabularySS: number;
    MechanicsSS: number;
    ComprehensionSS: number;
}

export class StudentSurveyResult {
    IsMathAssessed: boolean;
    IsVerbalAssessed: boolean;
    IsMathScored: boolean;
    IsVerbalScored: boolean;
    PeriodName: string;
    StartDate: Date;
    EndDate: Date;
    IsCurrent: boolean;
    Link: string;
    AssessmentId: number;
    Math: PDFMathTableData;
    Verbal: PDFVerbalTableData;
}

export interface ExportStudentLinkDTO {
    Name: string;
    Surname: string;
    StudentId: string;
    Link: string;
}